import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import ButtonGroup from '@mui/material/ButtonGroup';
import teenrules from '../../assets/images/teenpattirules.png'
export const ConfirmationDialog = ({ open, onClose, onConfirm, content, title }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="confirm-dialog-title"
      aria-describedby="confirm-dialog-description"
      BackdropProps={{ onClick: (e) => e.stopPropagation() }}
    >
      <DialogTitle id="confirm-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="confirm-dialog-description">
          {content}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button sx={{ backgroundColor: 'red', color: 'white' }} onClick={onClose} autoFocus>
          Cancle
        </Button>
        <Button sx={{ backgroundColor: 'blue', color: 'white' }} onClick={onConfirm} autoFocus>
          Bet
        </Button>
      </DialogActions>
    </Dialog>
  );
};



export const ConfirmBetDialog = ({ open, onClose, content, title }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="confirm-dialog-title"
      aria-describedby="confirm-dialog-description"
      BackdropProps={{ onClick: (e) => e.stopPropagation() }}
      PaperProps={{



        sx: {
          background: '#c2e59c',  /* fallback for old browsers */
          background: '-webkit-linear-gradient(to right, #64b3f4, #c2e59c)', /* Chrome 10-25, Safari 5.1-6 */
          background: 'linear-gradient(to right, #64b3f4, #c2e59c)',/* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
          padding: '10px 40px',
        },
      }}
    >
      <DialogTitle id="confirm-dialog-title" sx={{ color: 'white' }}> {/* Title color updated for contrast */}
        {title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="confirm-dialog-description" sx={{ color: 'white' }}>
          {content}
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'center' }}> {/* Centering the button */}
        <Button sx={{ backgroundColor: 'rgb(202 4 176 / 91%)', color: 'white', textAlign: 'center' }} onClick={onClose} autoFocus>
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
};




export const TeenpattiRulesDialog = ({ open, onClose }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="confirm-dialog-title"
      aria-describedby="confirm-dialog-description"
      BackdropProps={{ onClick: (e) => e.stopPropagation() }}
      PaperProps={{
        sx: {
          padding: '0px'
        },
      }}
    >
      <DialogContent sx={{ color: 'white', padding: '0px' }} >
        <DialogContentText id="confirm-dialog-description" sx={{ color: 'white', padding: '0px' }}>
          <img style={{ height: "450px", }} src={teenrules} />
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'center' }}> {/* Centering the button */}
        <Button sx={{ backgroundColor: 'rgb(202 4 176 / 91%)', color: 'white', textAlign: 'center' }} onClick={onClose} autoFocus>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};



export const BetSectionDialog = ({ open, onClose, content }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="confirm-dialog-title"
      aria-describedby="confirm-dialog-description"
      BackdropProps={{ onClick: (e) => e.stopPropagation() }}
      PaperProps={{
        sx: {
          background: '#c2e59c',
          background: '-webkit-linear-gradient(to right, #64b3f4, #c2e59c)',
          background: 'linear-gradient(to right, #64b3f4, #c2e59c)',
          width: '100%', // Ensure the dialog itself takes full width
          maxWidth: 'none' // Remove the maxWidth limitation
        },
      }}
    >
      <DialogContent sx={{ padding: 0 }}> {/* Remove padding to allow full width */}

        <DialogContentText id="confirm-dialog-description" sx={{ color: 'white', width: '100%' }}>
          {content}
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'center' }}>

      </DialogActions>
    </Dialog>
  );
};



export const BetSessionDialog = ({ open, onClose, selectedSession, selectedRate, betMode, amountInput, handelAmountclick, handleAmountChange, handelCancelkBet, loading, buttonValues, buttonValuesi, callBackCancel, callBackSubmit,winningamount,FancyType }) => {

const BookMType = FancyType === "BookMaker";
  

  return (
    <Dialog
      sx={{ margin: '0px !important' }}
      open={open} onClose={onClose}
      PaperProps={{
        sx: {
          margin: "10px", // Remove margin
          padding: 0, // Optionally remove padding
        }
      }}
    >


      <DialogTitle
        className="placebet-header"
        sx={{ padding: '0px', textAlign: 'center', }}
      >Place Your Bet</DialogTitle>
      <DialogContent
        sx={{ padding: '0px' }}
      >
        {selectedSession && (
          <div className="bet-section">
            <div style={{ fontSize: '16px', fontWeight: '600' }}>
              SESSION: <span style={{ color: "#000" }}>{selectedSession.name}</span>
            </div>
            <div style={{ fontSize: '16px', fontWeight: '600' }}>
              RATE: <span style={{ color: "#000" }}>{selectedRate} {`( ${betMode} ) `}</span>
            </div>
            <div className="enterbetAmount">
            <span style={{ fontSize: "16px", fontWeight: "bold", marginTop: '5px' }}>
  {BookMType && betMode === "No" 
    ? "Winning Amount : " 
    : "Amount : "
  }
</span>

              <input
                type="number"
                onChange={handleAmountChange}
                value={amountInput}
onFocus={()=>true}
                min={100}
                max=  {BookMType && betMode === "No" ? 200000 : 25000}
                defaultValue={0}
              />
             <div style={{ fontSize: '16px', fontWeight: '600' }}>
  {BookMType && betMode === "No" 
    ? <> Amount: <span style={{ color: "#000" }}>  {winningamount}</span></> 
    : <>Winning Amount: <span style={{ color: "#000" }}>{winningamount}</span></>
  }
</div>
              <div className="button-group mt-1">
                <ButtonGroup variant="contained" aria-label="Basic button group">
                  {buttonValuesi.map((btn, index) => (
                    <Button

                      style={{ fontSize: "12px" }}

                      key={index}
                      onClick={() => handelAmountclick(btn.value)}
                    >
                      {btn.label}
                    </Button>
                  ))}
                </ButtonGroup>
              </div>
              <div className="button-group mt-1 mb-2">
                <ButtonGroup variant="contained" aria-label="Basic button group">
                  {buttonValues.map((btn, index) => (
                    <Button

                      style={{ fontSize: '12px' }}
                      key={index}
                      onClick={() => handelAmountclick(btn.value)}
                    >
                      {btn.label}
                    </Button>
                  ))}
                </ButtonGroup>
              </div>
            </div>
          
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          disabled={loading}
          style={{ backgroundColor: "#1976D2", color: "white" }}
          onClick={callBackSubmit}
        >
          Done
        </Button>
        <Button
          onClick={callBackCancel}
          style={{ backgroundColor: "#1976D2", color: "white" }}
        >
          CANCEL
        </Button>
      </DialogActions>
    </Dialog>
  );
};



