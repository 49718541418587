import LiveTvIcon from "@mui/icons-material/LiveTv";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import { MatchSummary } from "../common/scorecardCard";
import { BackButton } from "../common/backbutton";
import { useNavigate, useParams } from "react-router-dom";
import { home, inplay } from "../../routesconfig/constant";
import { useEffect, useState } from "react";
import PlaceSassionBet, {
  GetFancyresult,
  GetMatchsList,
  GetscorecardLink,
} from "../../api/sassinmatch/sassionbet";
import GetCaller from "../../service/apiServices";
import { GetFancyBet } from "../../service/apiconst";
import { connect, useDispatch } from "react-redux";
import { useMatchSessionLive } from "../../api/sassinmatch/useMatchSessionLive";
import React, { useRef } from "react";
import { format } from "date-fns"; // Import format from date-fns
import { toZonedTime } from 'date-fns-tz';
import HeaderNotify from "../common/headerNotify";
import { FetchUserDetails } from "../../api/cassino/cassinogame";
import { setUserDetail } from "../../store/feature/authSlice";
import nodata from "../../assets/images/nodata.jpg"
import { pollingValue } from "../../service/utilities/poolingutils";
import { io } from "socket.io-client";
import BetDialog, { BetSessionDialog } from "../common/dialog";
import { Switch } from "@mui/material";
import { roundToDecimals } from "../../service/utilities/roundDecimal";
const InplaySession = () => {
  const welcomeRef = useRef(null);
  const dispatch = useDispatch()
  const scrollToContent = () => {
    // Scroll to the target element
    if (welcomeRef.current) {
      welcomeRef.current.scrollIntoView({
        behavior: "smooth",
        block: 'center',
        inline: 'center'

      });
    }
  };


  const [amountInput, setAmountinput] = useState("");
  const navigate = useNavigate();
  const [selectedSession, setSelectedSession] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedRate, setSelectedRate] = useState(null);
  const [betodds, setBettingOdds] = useState(null)
  const [betMode, setBetMode] = useState("");
  const [matchdetail, setMatchdetails] = useState([])
  const [selectedTossSessionIndex, setSelectedTossSessionIndex] = useState(null);
  const [selectedSessionIndex, setSelectedSessionIndex] = useState(null);
  const [matchsession, setMatchSassiondata] = useState()
  const [fancydata, setFancyData] = useState([]);
  const [fancytype, setFancyType] = useState([])
  const { matchId } = useParams();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [streemhide, setStreenHide] = useState(false)
  const [isVisible, setIsVisible] = useState(false);
  const [videoLink, setVideoLink] = useState("");
  const [livescorecard, setScorecardLink] = useState("")
  const [checked, setChecked] = useState(false);
  const [scorecardbool, setScorecardtrue] = useState(true)

  const handleToggle = (event) => {
    setChecked(event.target.checked);
  };



  const SOCKET_SERVER_URL = "https://automaticapiserver.bbet247.net";
  const PASS_YOUR_EVENT_ID = matchId; // Replace with your actual event ID


  useEffect(() => {
    if (!pollingValue) {

      const socket = io(SOCKET_SERVER_URL, { transports: ["websocket"] });
      socket.on("connect", () => {

        socket.emit("addOnEVENT", PASS_YOUR_EVENT_ID);
      });

      socket.on("updatedData", (data) => {
        // Do work with your data

        setMatchSassiondata(data.data);
      });

      // Error handling
      socket.on("connect_error", (err) => {

      });

      socket.on("error", (err) => {

      });



      // Cleanup function to disconnect the socket when the component unmounts
      return () => {
        socket.disconnect();
      };
    }
    {

      const interval = setInterval(sassionrefatch, 1500);
      return () => clearInterval(interval);    
    }

  }, []);




  const { response: livesassion, refetch: sassionrefatch } =
    useMatchSessionLive(matchId);

  const sassiondata = pollingValue ? livesassion : matchsession



  const livesassiondata = sassiondata?.data || [];
  const bookMakerArray = livesassiondata.filter(item => item.type === 'BookMaker');
  const tossArray = livesassiondata.filter(item => item.type === 'Toss');
  const fancyArray = livesassiondata.filter(item => item.type === 'Session');



  const { response: matchlist } = GetMatchsList();



  const matchdetails = matchlist?.data.filter(item => String(item.marketId) === String(matchId));



  const streamingLink = matchdetails?.length > 0 && matchdetails[0]?.streamingLink;
  const scorecardLink = matchdetails?.length > 0 && matchdetails[0]?.scorecardLink;
  const eventId = matchdetails?.length > 0 && matchdetails[0]?.id



  useEffect(() => {
    if (typeof streamingLink === "string" && scorecardLink.startsWith('http')) {
      setVideoLink(streamingLink)
    }
  }, [streamingLink])

  const { response: responseScorecard } = GetscorecardLink(matchId);

  const scorelink = responseScorecard?.data


  
  useEffect(() => {

    if ((typeof scorecardLink === 'string' && scorecardLink.startsWith('http')) || (typeof scorelink === 'string') && scorelink.startsWith('http')) {
      if (scorecardLink.startsWith('http')) {
        setScorecardLink(scorecardLink);
      }
      else if (scorelink.startsWith('http')) {
        setScorecardLink(scorelink);
      }
    }
    else {
      setScorecardLink("");
      setScorecardtrue(false)
    }
  }, [scorecardLink,scorelink]); // Dependencies

  const { response: responseprofile, refetch: Refetchprofile } =
    FetchUserDetails();
  const userdetails = responseprofile?.data;

  dispatch(setUserDetail(userdetails));

  useEffect(() => {
    // const intervalId = setInterval(sassionrefatch, 1500);
    const intervalprofile = setInterval(Refetchprofile, 5000);


    return () => clearInterval(intervalprofile);
  }, []);

  const handleClick = () => {
    navigate(inplay);
  };




  const handleSessionClick = (session, mode, betvolumn, betOdds, index, ftype) => {
    console.log(ftype)
    setOpenDialog(true)
    handleOpen();
    scrollToContent();
    setSelectedSession(session);
    setSelectedRate(betOdds);
    setBettingOdds(betvolumn)
    setSelectedSessionIndex(index === selectedSessionIndex ? null : index);
    setSelectedTossSessionIndex(index === selectedTossSessionIndex ? null : index)
    setBetMode(mode);
    setFancyType(ftype)
    if (ftype === 'Toss') {
      setSelectedTossSessionIndex(index);  // Set the index for Toss section
      setSelectedSessionIndex(null); // Ensure the Session section closes
    } else if (ftype === 'Session') {
      setSelectedSessionIndex(index);  // Set the index for Session section
      setSelectedTossSessionIndex(null); // Ensure the Toss section closes
    }

  };

  // const handleAmountChange = (event) => {
  //   if (event && event.target) {
  //     setAmountinput(event.target.value);
  //   }
  // };
  const handleAmountChange = (event) => {
    if (event && event.target) {
      let value = parseInt(event.target.value, 10);
  
      // Check for the special condition: fancytype === "BookMaker" && betMode === "No"
      if (fancytype === "BookMaker" && betMode === "No") {
        // If the value is less than or equal to 200000, set it to 200000
        if (value > 200000) {
          value = 200000;
        }
      } else {
        // Otherwise, apply the max limit of 25000
        if (value > 25000) {
          value = 25000; // Set to maximum if too high
        }
      }
  
      setAmountinput(value);
    }
  };
  
  



  const fanctBetResult = (item) => {
    GetCaller({
      API: GetFancyBet,
      dispatch: (e) => { },
      item: item,
      callback: ({ response }) => {
        const fancydata = response?.data?.data;
        setFancyData(fancydata);
      },
    });
  };

  useEffect(() => {
    const intervalfancy = setInterval(fancyRefetch, 3000);
    return () => clearInterval(intervalfancy);
  }, [eventId])

  const { response: fancyResult, refetch: fancyRefetch } =
    GetFancyresult(eventId);


  const data1 = fancyResult?.data?.filter((item) => item.completed === true);


  const data2 = fancyResult?.data?.filter((item) => item.completed === false);

  const costTotal = data1?.reduce((acc, item) => {
    // Ensure profitAndLoss is a number, defaulting to 0 if undefined or null
    return acc + (item?.profitAndLoss ? parseFloat(item?.profitAndLoss) : 0);
  }, 0);


  const { response, loading, submit } = PlaceSassionBet();

  const galaSession = data2?.find(item => item?.session === selectedSession?.name);

  console.log("galasession",galaSession)
  

  const handelPlaceBet = () => {
    // Check for the specific condition: if fancytype is "BookMaker" and betMode is "No"
    if (fancytype === "BookMaker" && betMode === "No") {
      // If winningamount is greater than 100, proceed with placing the bet
      if (winningamount > 100) {
        if (selectedSession && amountInput > 99) {
          const betData = {
            eventId: eventId.toString(),
            sessionId: String(selectedSession.selectionId),
            session: selectedSession.name,
            run: fancytype === "Toss" ? betodds * 100 : parseFloat(betodds),
            rate: parseFloat(selectedRate),
            amount: winningamount, // Use winningamount for "BookMaker" and "No"
            mode: betMode,
            oddType: fancytype
          };
  
          // Submit the betData
          submit(betData, (response) => {
            if (response) {
              fancyRefetch(response?.data?.id);
              Refetchprofile();
              setAmountinput("");
              setSelectedSession(null);
              setBettingOdds(null);
              setBetMode("");
            }
          });
        }
      } else {
        // Optionally, you can show an alert or handle the case when winningamount <= 100
        console.log("Winning amount should be greater than 100.");
      }
    } else {
      // If fancytype is not "BookMaker" or betMode is not "No", run the same logic
      if (selectedSession && amountInput > 99) {
        const betData = {
          eventId: eventId.toString(),
          sessionId: String(selectedSession.selectionId),
          session: selectedSession.name,
          run: fancytype === "Toss" ? betodds * 100 : parseFloat(betodds),
          rate: parseFloat(selectedRate),
          amount:amountInput, // Use amountInput for other cases
          mode: betMode,
          oddType: fancytype
        };
  
        // Submit the betData
        submit(betData, (response) => {
          if (response) {
            fancyRefetch(response?.data?.id);
            Refetchprofile();
            setAmountinput("");
            setSelectedSession(null);
            setBettingOdds(null);
            setBetMode("");
          }
        });
      }
    }
  
    // Close the dialog and reset states after attempting to place the bet
    setSelectedSession(null);
    handleClose();
    setOpenDialog(false);
  };
  

  const handelCancelkBet = () => {
    setSelectedSession(null)
  }

  const winningamount = fancytype === "BookMaker" && betMode === "No" ?  roundToDecimals(amountInput * (parseFloat(selectedRate))) : roundToDecimals(amountInput * (parseFloat(selectedRate)));


  const fancy = false;

  const buttonValuesi = [
    {
      label: "₹ 100",
      value: 100,
      style: { backgroundColor: "#bb459c", padding: "4px 0px" },
    },
    {
      label: "₹ 200",
      value: 200,
      style: { backgroundColor: "#bb459c", padding: "4px 0px" },
    },
    {
      label: "₹ 500",
      value: 500,
      style: { backgroundColor: "#bb459c", padding: "4px 0px" },
    },
    {
      label: "₹ 1000",
      value: 1000,
      style: { backgroundColor: "#bb459c", padding: "4px 0px" },
    },
    {
      label: "₹ 5K",
      value: 5000,
      style: { backgroundColor: "#bb459c", padding: "4px 0px" },
    },

  ];

  const buttonValues = [
    {
      label: "₹ 10K",
      value: 10000,
      style: { backgroundColor: "#bb459c", padding: "4px 0px" },
    },
    {
      label: "₹ 20K",
      value: 20000,
      style: { backgroundColor: "#bb459c", padding: "4px 0px" },
    },
    {
      label: "₹ 25K",
      value: 25000,
      style: { backgroundColor: "#bb459c", padding: "4px 0px" },
    },
    {
      label: "₹ 0",
      value: 0,
      style: { backgroundColor: "red", padding: "4px 0px" },
    }, // Custom style for this button
  ];

  const handleCancelBet = () => {
    setOpenDialog(false);
  };

  const handelAmountclick = (amountvalue) => {
    setAmountinput(amountvalue);
  };

  const handleInputChange = (event) => {
    // Ensure event and event.target exist before accessing
    if (event && event.target) {
      setAmountinput(event.target.value);
    }
  };

  const betBool = false;




  return (
    <>

      <BetSessionDialog
        open={openDialog}
        onClose={handleCancelBet}
        FancyType = {fancytype}
        selectedSession={selectedSession}
        selectedRate={selectedRate}
        betMode={betMode}
        amountInput={amountInput}
        winningamount={winningamount}
        handelAmountclick={handelAmountclick}
        handleAmountChange={handleAmountChange}
        loading={loading}
        buttonValues={buttonValues}
        buttonValuesi={buttonValuesi}
        callBackSubmit={handelPlaceBet}
        callBackCancel={handleCancelBet}
      />


      <div style={{ marginTop: '10px' }}>
        <BackButton onClick={handleClick} />
      </div>
      <div className="session-container mt-2">


        <div>
          {videoLink &&
          <div className="tv-section d-flex">
            <a href=''>  <LiveTvIcon sx={{ color: "yellow", fontSize: "30px", marginBottom: '5px', paddingLeft: '3px' }} /> </a>
            <Switch
              checked={checked}
              onChange={handleToggle}
              color="default" // Prevents default blue color
              sx={{
                '& .MuiSwitch-thumb': {
                  backgroundColor: checked ? '#5CDC00' : 'gray', // Thumb color
                },
                '& .MuiSwitch-track': {
                  backgroundColor: checked ? 'green' : '', // Track color
                },
                '&.Mui-checked .MuiSwitch-thumb': {
                  backgroundColor: 'white', // Thumb color when checked
                },
              }}


              label={checked ? "Yes" : "No"}
            />
          </div>
}
          {checked && videoLink &&
            <iframe
              src={videoLink}
              width="100%"
              height="230"
            // frameborder="0"
            // allowfullscreen
            >
            </iframe>
          }
        </div>


        {livescorecard &&
          <MatchSummary scorelink={livescorecard} />

        }

        <div className="score-section"></div>

        {bookMakerArray.length != 0 &&



          <div className="session-grid">
            <div className="session-header">
              <div className="session-header-left tc hh-tx">
                MIN:100 MAX:25000
              </div>
              <div className="session-header-right hh-tx">
                <div style={{ borderRight: "1px solid #ffffff" }}>KHAI</div>
                <div>LAGAI</div>
              </div>
            </div>

  {bookMakerArray[0]?.oddList?.map((session, index) => (
              <div>
              <div className="session-body">
                <div className="session-body-left">
                  <div className="h-tx"> {session.name}</div>
                  <span className="l-tx">Session Limit: {session.max}</span>
                </div>
                <div className="session-body-right">
                <div className="lay-section"
                    onClick={() =>
             
                     session.status !== 'SUSPENDED' && session.layOdds > 0 && bookMakerArray[0].type === "BookMaker" && handleSessionClick(session, "No", session.layVolume, session.layOdds, index, bookMakerArray[0].type)
                    }
                  >
                    <div className="h-tx">{session.layOdds}</div>
                    <span className="l-tx">{session.layVolume}</span>
                  </div>


                  <div className="back-section"
                    onClick={() =>
                    
                       session.status !== 'SUSPENDED' && session.backOdds > 0 && bookMakerArray[0].type === "BookMaker" && handleSessionClick(session, "Yes", session.backVolume, session.backOdds, index, bookMakerArray[0].type)
                    }
                  >
                    <div className="h-tx">{session.backOdds}</div>
                    <span className="l-tx">{session.backVolume}</span>
                  </div>
                  {(session.status === 'SUSPENDED' || session.status === 'Ball Running') &&
                    <div className="
          ">{session.status}</div>
                  }
                </div>


              </div>
         
            </div>
                 ))}   
           
          </div>
                
        }

        {tossArray[0]?.oddList.length != (0 || undefined) && 
          <div className="session-grid">
            <div className="session-header">
              <div className="session-header-left hh-tx tc">
                TOSS SESSION
              </div>
              <div className="session-header-right ">
                <div className="" style={{ borderRight: "1px solid #ffffff" }}></div>
                <div></div>
              </div>
            </div>
            {tossArray[0]?.oddList?.map((session, index) => (
              <div>
                <div className="session-body">
                  <div className="session-body-left">
                    <div className="h-tx"> {session.name}</div>
                    <span className="l-tx">Session Limit: {session.max}</span>
                  </div>
                  <div className="session-body-right">
                    <div className="lay-section" style={{ borderRight: "1px solid white" }}
                    
                    >
                      <div className="h-tx">0</div>
                      <span className="l-tx">0</span>
                    </div>


                    <div className="back-section"
                      onClick={() =>
                        session.status !== 'SUSPENDED' && session.backVolume !== "0" && tossArray[0].type === "Toss" && handleSessionClick(session, "Yes", session.backVolume, session.backOdds, index, tossArray[0].type)
                      }
                    >
                      <div className="h-tx">{session.backOdds}</div>
                      <span className="l-tx">{session.backVolume}</span>
                    </div>
                    {(session.status === 'SUSPENDED' || session.status === 'Ball Running') &&
                      <div className="
            ">{session.status}</div>
                    }
                  </div>


                </div>
                {/* {selectedTossSessionIndex === index && selectedSession && (
                  <div ref={welcomeRef} className="bet-section">
                    <div style={{ fontSize: '16px', fontWeight: '600' }}>
                      SESSION:{" "}
                      <span style={{ color: "#000" }}>{selectedSession.name}</span>
                    </div>
                    <div style={{ fontSize: '16px', fontWeight: '600' }}>
                      RATE:{" "}
                      <span style={{ color: "#000" }}>
                        {selectedRate} ({betMode})
                      </span>
                    </div>
                    <div className="enterbetAmount">
                      <span style={{ fontSize: "16px", fontWeight: "bold" }}>Amount:{" "}</span>
                      <input
                        style={{}}
                        type="number"
                        onChange={handleAmountChange}
                        value={amountInput}
                        defaultValue={0}
                      />


                      <div className="button-group mt-1">
                        <ButtonGroup
                          variant="contained"
                          aria-label="Basic button group"
                        >
                          {buttonValuesi.map((btn, index) => (
                            <Button
                              sx={btn.style}
                              key={index}
                              onClick={() => handelAmountclick(btn.value)}
                            >
                              {btn.label}
                            </Button>
                          ))}
                        </ButtonGroup>
                      </div>
                      <div className="button-group mt-1 mb-2">
                        <ButtonGroup
                          variant="contained"
                          aria-label="Basic button group"
                        >
                          {buttonValues.map((btn, index) => (
                            <Button
                              sx={btn.style}
                              key={index}
                              onClick={() => handelAmountclick(btn.value)}
                            >
                              {btn.label}
                            </Button>
                          ))}
                        </ButtonGroup>
                      </div>
                      <div style={{ textAlign: 'end' }} className="mt-2">
                        <button
                          // onClick={handelPlaceBet}
                          disabled={loading}
                          style={{ backgroundColor: "#1976D2", padding: '7px 45px', border: 'none', marginRight: '10px' }}
                        >
                          Done
                        </button>

                        <button
                          // onClick={handelCancelkBet}
                          style={{ backgroundColor: "#1976D2", padding: '7px 45px', border: 'none' }}
                        >
                          CANCEL
                        </button>
                      </div>
                    </div>
                  </div>
                )} */}
              </div>
            ))}
          </div>
        }

        {fancyArray[0]?.oddList.length != 0 && fancyArray.length != 0 &&

          <div className="session-grid">
            <div className="session-header">
              <div className="session-header-left hh-tx tc">
                SESSION
              </div>
              <div className="session-header-right hh-tx">
                <div style={{ borderRight: "1px solid #ffffff" }}>NO</div>
                <div>YES</div>
              </div>
            </div>
            {fancyArray[0]?.oddList?.map((session, index) => (

              <div>
                <div className="session-body">
                  <div className="session-body-left">
                    <div className="h-tx"> {session.name}</div>
                    <span className="l-tx">Session Limit: {session.minBetSize}</span>
                  </div>

                  <div className="session-body-right">
                    <div className="lay-section" style={{ borderRight: "1px solid white" }}
                      onClick={() =>
                        session.status !== 'SUSPENDED' && session.layOdds !== "0" && handleSessionClick(session, "No", session.layVolume, session.layOdds, index, fancyArray[0].type)
                      }
                    >
                      <div className="h-tx">{session?.layVolume}</div>
                      <span className="l-tx">{session.layOdds}</span>
                    </div>

                    <div className="back-section"
                      onClick={() =>
                        session.status !== 'SUSPENDED' && session.backOdds !== "0" && handleSessionClick(session, "Yes", session.backVolume, session.backOdds, index, fancyArray[0].type)
                      }
                    >
                      <div className="h-tx">{session.backVolume}</div>
                      <span className="l-tx">{session.backOdds}</span>
                    </div>
                    {(session.status === 'SUSPENDED' || session.status === 'Ball Running') &&
                      <div className="overlap-content">{session.status}</div>
                    }
                  </div>


                </div>

                {/* {selectedSessionIndex === index && selectedSession && (
                  <div ref={welcomeRef} className="bet-section">
                    <div style={{ fontSize: '16px', fontWeight: '600' }}>
                      SESSION:{" "}
                      <span style={{ color: "#000" }}>{selectedSession.name}</span>
                    </div>
                    <div style={{ fontSize: '16px', fontWeight: '600' }}>
                      RATE:{" "}
                      <span style={{ color: "#000" }}>
                        {selectedRate} ({betMode})
                      </span>
                    </div>
                    <div className="enterbetAmount">
                      <span style={{ fontSize: "16px", fontWeight: "bold" }}>Amount:{" "}</span>
                      <input
                        style={{}}
                        type="number"
                        onChange={handleAmountChange}
                        value={amountInput}
                        defaultValue={0}
                      />


                      <div className="button-group mt-1">
                        <ButtonGroup
                          variant="contained"
                          aria-label="Basic button group"
                        >
                          {buttonValuesi.map((btn, index) => (
                            <Button
                              sx={btn.style}
                              key={index}
                              onClick={() => handelAmountclick(btn.value)}
                            >
                              {btn.label}
                            </Button>
                          ))}
                        </ButtonGroup>
                      </div>
                      <div className="button-group mt-1 mb-2">
                        <ButtonGroup
                          variant="contained"
                          aria-label="Basic button group"
                        >
                          {buttonValues.map((btn, index) => (
                            <Button
                              sx={btn.style}
                              key={index}
                              onClick={() => handelAmountclick(btn.value)}
                            >
                              {btn.label}
                            </Button>
                          ))}
                        </ButtonGroup>
                      </div>
                      <div style={{ textAlign: 'end' }} className="mt-2">
                        <button
                          onClick={handelPlaceBet}
                          disabled={loading}
                          style={{ backgroundColor: "#1976D2", padding: '7px 45px', border: 'none', marginRight: '10px' }}
                        >
                          Done
                        </button>

                        <button
                          onClick={handelCancelkBet}
                          style={{ backgroundColor: "#1976D2", padding: '7px 45px', border: 'none' }}
                        >
                          CANCEL
                        </button>
                      </div>
                    </div>
                  </div>
                )} */}

              </div>
            ))}
          </div>
        }

      </div>

      {
        fancyArray[0]?.oddList?.length === 0 && (!tossArray[0]?.oddList || tossArray[0]?.oddList.length === 0) && (!bookMakerArray[0]?.oddList || bookMakerArray[0]?.oddList.length === 0 ) || sassiondata?.data === null &&
        <div className="nodata-img">
          <img src={nodata} />
        </div>
      }


      {data2 && data2.length !== 0 && (
        <div className="fancy-bet">
          <div
            style={{
              backgroundColor: " #636062",
              color: "white",
              textAlign: "center",
              width: "100%",
              padding: '5px 0px',
              fontSize: '16px'

            }}
          >
            <h6 className="m-0">FANCY BETS</h6>
          </div>
          <div className="fancy-bet-table">
          <table border="1">
            <thead>
              <tr>
                <th>SESSION</th>
                <th>TYPE</th>
                <th>RUN</th>
                <th>RATE</th>
                <th>AMOUNT</th>
                <th>MODE</th>
              </tr>
            </thead>
            <tbody>
              {data2.map((item, index) => {
                     const gamemode = item.oddType  === "BookMaker"  ? (item.mode === "No" ? "Khai" : "Lagai") : (item.mode);
                return(
                <tr key={index}>
                  <td style={{ textAlign: 'start' }}>
                    <span style={{ fontWeight: "600", fontSize: "12px" }}>
                      {item.session}
                    </span>
                    <br />
                  </td>
                  <td>
                    <span>{item.oddType}</span>
                  </td>
                  <td>
                    <span>{item.run}</span>
                  </td>
                  <td>
                    <span>{item.rate}</span>
                  </td>
                  <td>
                    <span>{item.amount}</span>
                  </td>
                  <td>
                    <span>{gamemode}</span>
                  </td>
                </tr>
                )
})}
            </tbody>
          </table>
          </div>
        </div>
      )}

      {data1 && data1.length !== 0 && (
        <div className="fancy-bet">
          <div
            style={{
              backgroundColor: "#636062",
              color: "white",
              textAlign: "center",
              width: "100%",
              padding: '5px 0px',
              fontSize: '16px'
            }}
          >
            <h6 className="m-0"> COMPLETED FANCY BETS </h6>
            <div style={{textAlign:'center',fontSize:'16px',fontWeight:'500'}}>Total P/L : <span style={{color: costTotal < 0 ? 'red' :'blue'}}>{roundToDecimals(costTotal)}</span></div>
          </div>
          <div className="fancy-bet-table">
          
            <table border="1">
              <tr>
                <th>RUNNER</th>
                <th>TYPE</th>
                <th>DATE</th>
                <th>RUN</th>
                <th>RATE</th>
                <th>AMOUNT</th>
                <th>MODE</th>
                <th>P&L</th>
                <th>RESULT</th>
              </tr>

              {data1.map((item, index) => {
                const newdate = item.createdDateTime;
                const utcDate = toZonedTime(new Date(newdate), 'UTC');
                const formattedDate = format(utcDate, "d MMMM h:mm a");
                const gamemode = item.oddType  === "BookMaker"  ? (item.mode === "No" ? "Khai" : "Lagai") : (item.mode);
                return (
                  <tr key={index}>
                    <td style={{ textAlign: 'start' }}>
                      <span style={{ fontWeight: "600", fontSize: "12px" }}>
                        {item.session}
                      </span>
                      <br />
                    </td>
                    <td>
                    <span>{item.oddType}</span>
                  </td>
                    <td>
                      <span>{formattedDate}</span>
                    </td>
                 
                    <td>
                      <span>{item.run}</span>
                    </td>
                    <td>
                      <span>{item.rate}</span>
                    </td>
                    <td>
                      <span>{item.amount}</span>
                    </td>

                    <td>
                      <span>{gamemode}</span>
                    </td>
                    <td>
                      <span>{roundToDecimals(item.profitAndLoss) || ""}</span>
                    </td>
                    <td>
                      <span>{item.result}</span>
                      <span>{item.completed}</span>
                    </td>

                  </tr>
                )
              })}

            </table>
          </div>
        </div>
      )}

   

      <div className="mt-2">
        <BackButton onClick={handleClick} />
      </div>
    </>
  );
};
export default InplaySession;







