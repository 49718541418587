import card1 from "../../assets/images/cardimage/1.jpg";
import card2 from "../../assets/images/cardimage/2.jpg";
import card3 from "../../assets/images/cardimage/3.jpg";
import card4 from "../../assets/images/cardimage/4.jpg";
import card5 from "../../assets/images/cardimage/5.jpg";
import card6 from "../../assets/images/cardimage/6.jpg";
import card7 from "../../assets/images/cardimage/7.jpg";
import card8 from "../../assets/images/cardimage/8.jpg";
import card9 from "../../assets/images/cardimage/9.jpg";
import card10 from "../../assets/images/cardimage/10.jpg";
import card11 from "../../assets/images/cardimage/11.jpg";
import card12 from "../../assets/images/cardimage/12.jpg";
import card13 from "../../assets/images/cardimage/13.jpg";
import blockcard from "../../assets/images/cardimage/block.jpg";
import check from '../../assets/images/check.png'
import { useEffect, useState } from "react";
import { BackButton } from "../common/backbutton";
import { casino, home } from "../../routesconfig/constant";
import { useNavigate } from "react-router-dom";
import { Cassinoifram } from "./gameIframe/casinoifram";

import SubmitCasinoBet, { FetchUserDetails, GetcasinoBetList, GetcasinoData, GetcasinoResult } from "../../api/cassino/cassinogame";
import { teenpattilive } from "../../service/model";
import PlacebetCasino from "../common/placebetcasino";
import { BetSectionDialog, ConfirmBetDialog } from "../common/dialog";
import moment from "moment-timezone";
import FlipCountdown from "../common/flipcountdown";
import CompletedBetList from "./utilities/casinoTable";
import { checkProfitLoss } from "./utilities/checkCasinoprofitLoss";
import { formatDate } from "date-fns";
import { pollingValue } from "../../service/utilities/poolingutils";
import { io } from "socket.io-client";

const AndarBahar = () => {
  const navigate = useNavigate();
  const canclebet = true;

  const cardsimg = []


  const blockcardsimg = [
    blockcard,
    blockcard,
    blockcard,
    blockcard,
    blockcard,
    blockcard,
    blockcard,
    blockcard,
    blockcard,
    blockcard,
    blockcard,
    blockcard,
    blockcard,
  ];

  const [placebet, setPlacebet] = useState(false);
  const [stakeamount, setStakeAmount] = useState(0)
  const [confirmbet, setBetConform] = useState(false);
  const [betdata, setbetdata] = useState([]);
  const [abbetResult, setabResult] = useState([]);
const [abdata, setAbData] = useState([]);

const SOCKET_SERVER_URL = "https://automaticapiserver.bbet247.net";
const PASS_YOUR_EVENT_ID = "ab2"; // Replace with your actual event ID




  useEffect(() => {
    if(!pollingValue){
    
    
        const socket = io(SOCKET_SERVER_URL, { transports: ["websocket"] });
        socket.on("connect", () => {
    
          socket.emit("addOnEVENT", PASS_YOUR_EVENT_ID);
        });
    
        socket.on("updatedData", (data) => {
          // Do work with your data
      
          setAbData(data.data);   
          setabResult(data.data.result)
        });
    
         // Error handling
      socket.on("connect_error", (err) => {
        console.error("Connection error:", err);
      });
    
      
      socket.on("error", (err) => {
        console.error("Socket error:", err);
      });
    
    
    
        // Cleanup function to disconnect the socket when the component unmounts
        return () => {
          socket.disconnect();
        };
      }
    {
    
        const interval = setInterval(casinoRefetch, 1000);
        return () => clearInterval(interval);
    }
    
      }, []);
   
  const betConform = () => {
    setBetConform(true);
  };


  const handelPlacebet = (value) => {
    setPlacebet(true)
    setbetdata(value)
  }
  const handleClose = () => {
    setBetConform(false);
    setPlacebet(false)
  };

  const getISTDate = () => {
    return moment().tz('Asia/Kolkata').format('DD-MM-YYYY');
  };

  const getISTTime = () => {
    return moment().tz('Asia/Kolkata').format('HH:mm:ss');
  };

  const handlingbet = (value) => {
    setStakeAmount(value);

  };
  const betprofit = stakeamount * betdata?.rate;
  const handleClick = () => {
    navigate(casino);
  };

  const { response: responseprofile, refetch: Refetchprofile } = FetchUserDetails();
  const userdetails = responseprofile?.data;


  useEffect(() => {
    const interval2Id = setInterval(casinoResultrefetch, 1000);
    return () => clearInterval(interval2Id);
  }, []);

  const { response: andarbaharresult, refetch: casinoResultrefetch } = GetcasinoResult("ab20");

  const AndarBaharResult = andarbaharresult?.data || [];

  const { response: abData, refetch: casinoRefetch } =
    GetcasinoData("ab20");

    useEffect(() => {
      if (abData) { 
        setabResult(abData?.data?.result);
      }
    }, [abData]);

    const andarbahardata = pollingValue ? abData?.data : abdata?.data;



  const t1 = andarbahardata?.data?.t1;
  const timmer = t1 && t1[0]?.autotime;
  const gtype = t1 && t1[0]?.gtype
  const t3 = andarbahardata?.data?.t3;
  const t2 = andarbahardata?.data?.t2;
  const roundid = t1 && t1[0]?.mid
  const tableroundId = roundid && roundid.toString().split('.')[1] || "0"

  console.log(roundid)
  console.log(t1)
  console.log(t2)
  console.log(t3)

  const allandarcard = t3 && t3[0]?.aall;
  const allbaharcard = t3 && t3[0]?.ball;
  const Andarcard = t3 && t3[0]?.ar;
  const Baharcard = t3 && t3[0]?.br;


  const { response: casinoResestList, refetch: casinoRefetchList } =  GetcasinoBetList("ab20");
  const  betlist = casinoResestList?.data || []
  const completedBetList = casinoResestList?.data
  ?.filter((res) => res.completed)
  ?.map((res) => ({
    ...res,
    createdDateTime: formatDate(res?.createdDateTime),
  }));

  const notCompletedBetList = casinoResestList?.data
  ?.filter((res) => !res.completed)
  ?.map((res) => ({
    ...res,
 
  }));
  


  const { response, loading, submit } = SubmitCasinoBet();

  const betSubmitConform = () => {
    const data = {
      gameType: gtype,
      roundId: betdata?.mid,
      rate: betdata?.rate,
      amount: stakeamount,
      selection: betdata?.nation,
    };
    if (data.amount >= 200 && data.amount <= 25000) {
      submit(data, (response) => {
        if (response?.metaData?.resultMessage === "Success") {
       
          casinoRefetchList()
          betConform()
          Refetchprofile()
          setStakeAmount("")
          handleClose()
        }
      });
    };
  }

  let ACard1,
    ACard2,
    ACard3,
    ACard4,
    ACard5,
    ACard6,
    ACard7,
    ACard8,
    ACard9,
    ACard10,
    ACardJ,
    ACardQ,
    ACardK,
    BCard1,
    BCard2,
    BCard3,
    BCard4,
    BCard5,
    BCard6,
    BCard7,
    BCard8,
    BCard9,
    BCard10,
    BCardJ,
    BCardQ,
    BCardK;

  if (Array.isArray(t2)) {
    ACard1 = t2[0];
    ACard2 = t2[1];
    ACard3 = t2[2];
    ACard4 = t2[3];
    ACard5 = t2[4];
    ACard6 = t2[5];
    ACard7 = t2[6];
    ACard8 = t2[7];
    ACard9 = t2[8];
    ACard10 = t2[9];
    ACardJ = t2[10];
    ACardQ = t2[11];
    ACardK = t2[12];
    BCard1 = t2[13];
    BCard2 = t2[14];
    BCard3 = t2[15];
    BCard4 = t2[16];
    BCard5 = t2[17];
    BCard6 = t2[18];
    BCard7 = t2[19];
    BCard8 = t2[20];
    BCard9 = t2[21];
    BCard10 = t2[22];
    BCardJ = t2[23];
    BCardQ = t2[24];
    BCardK = t2[25];
  }

  const AndarBaharCardList = [
    { ACard1 },
    { ACard2 },
    { ACard3 },
    { ACard4 },
    { ACard5 },
    { ACard6 },
    { ACard7 },
    { ACard8 },
    { ACard9 },
    { ACard10 },
    { ACardJ },
    { ACardQ },
    { ACardK },
    { BCard1 },
    { BCard2 },
    { BCard3 },
    { BCard4 },
    { BCard5 },
    { BCard6 },
    { BCard7 },
    { BCard8 },
    { BCard9 },
    { BCard10 },
    { BCardJ },
    { BCardQ },
    { BCardK },
  ];

  const cardsimgAndarAfterBet = [
    { card: blockcard, value: "0", data: [] },
    { card: card1, value: "1", data: ACard1 },
    { card: card2, value: "2", data: ACard2 },
    { card: card3, value: "3", data: ACard3 },
    { card: card4, value: "4", data: ACard4 },
    { card: card5, value: "5", data: ACard5 },
    { card: card6, value: "6", data: ACard6 },
    { card: card7, value: "7", data: ACard7 },
    { card: card8, value: "8", data: ACard8 },
    { card: card9, value: "9", data: ACard9 },
    { card: card10, value: "10", data: ACard10 },
    { card: card11, value: "11", data: ACardJ },
    { card: card12, value: "12", data: ACardQ },
    { card: card13, value: "13", data: ACardK },

    { card: card1, value: "21", data: BCard1 },
    { card: card2, value: "22", data: BCard2 },
    { card: card3, value: "23", data: BCard3 },
    { card: card4, value: "24", data: BCard4 },
    { card: card5, value: "25", data: BCard5 },
    { card: card6, value: "26", data: BCard6 },
    { card: card7, value: "27", data: BCard7 },
    { card: card8, value: "28", data: BCard8 },
    { card: card9, value: "29", data: BCard9 },
    { card: card10, value: "30", data: BCard10 },
    { card: card11, value: "31", data: BCardJ },
    { card: card12, value: "32", data: BCardQ },
    { card: card13, value: "33", data: BCardK },

  ];
  const cardsimgAndarPlacebet = [
    { card: blockcard, value: "1", data: ACard1 },
    { card: blockcard, value: "2", data: ACard2 },
    { card: blockcard, value: "3", data: ACard3 },
    { card: blockcard, value: "4", data: ACard4 },
    { card: blockcard, value: "5", data: ACard5 },
    { card: blockcard, value: "6", data: ACard6 },
    { card: blockcard, value: "7", data: ACard7 },
    { card: blockcard, value: "8", data: ACard8 },
    { card: blockcard, value: "9", data: ACard9 },
    { card: blockcard, value: "10", data: ACard10 },
    { card: blockcard, value: "11", data: ACardJ },
    { card: blockcard, value: "13", data: ACardQ },
    { card: blockcard, value: "12", data: ACardK },

  ]

  const cardsimgBaharPlacebet = [
    { card: blockcard, value: "21", data: BCard1 },
    { card: blockcard, value: "22", data: BCard2 },
    { card: blockcard, value: "23", data: BCard3 },
    { card: blockcard, value: "24", data: BCard4 },
    { card: blockcard, value: "25", data: BCard5 },
    { card: blockcard, value: "26", data: BCard6 },
    { card: blockcard, value: "27", data: BCard7 },
    { card: blockcard, value: "28", data: BCard8 },
    { card: blockcard, value: "29", data: BCard9 },
    { card: blockcard, value: "30", data: BCard10 },
    { card: blockcard, value: "31", data: BCardJ },
    { card: blockcard, value: "32", data: BCardQ },
    { card: blockcard, value: "33", data: BCardK },
  ];


  useEffect(() => {
    if (BCard3?.gstatus === "0") {
      handleClose()
    }
  }, [andarbahardata])



  const cardValues = allandarcard && allandarcard.split(",");
  const cardValues2 = allbaharcard && allbaharcard.split(",");

  const andraTableCardId = Andarcard && Andarcard.split(",");
  const BaharTableCardId = Baharcard && Baharcard.split(",");
  const betReset = () => {
    setStakeAmount("")
  }

  const mappedCardsAndarTable = Array.isArray(andraTableCardId) ? andraTableCardId.map((liveValue) => {
    const matchedItem = cardsimgAndarAfterBet.find(cardItem => cardItem.value === liveValue.toString());
    return matchedItem ? matchedItem : null;
  }).filter(item => item !== null) : [];

  const mappedCardsBaharTable = Array.isArray(BaharTableCardId) ? BaharTableCardId.map((liveValue) => {
    const matchedItem = cardsimgAndarAfterBet.find(cardItem => cardItem.value === liveValue.toString());
    return matchedItem ? matchedItem : null;
  }).filter(item => item !== null) : [];

  useEffect(() => {
    if (timmer === "20") {
      Refetchprofile()
      casinoRefetchList();
    }
  }, [timmer])

const columns = [
  { id: "selection", label: "Bet" },
  { id: "rate", label: "Rate" },
  { id: "amount", label: "Amount" },
  { id: "result", label: "Result" },
  { id: "createdDateTime", label: "Date & Time" },
  { id: "profitAndLoss", label: "P/L" },
];


const columnsopenbet = [
  { id: "selection", label: "Match Bet" },
  { id: "rate", label: "Odds" },
  { id: "amount", label: "Stake" },

];


  return (
    <>
 
      {
        BCard3?.gstatus !== "0" &&

        <BetSectionDialog
          onClose={handleClose}
          open={placebet}
          content={
            <PlacebetCasino
              placebet={placebet}
              setPlacebet={setPlacebet}
              setStake={setStakeAmount}
              stake={stakeamount}
              odds={betdata && betdata.rate}
              betnation={betdata && betdata.nation}
              betprofit={betprofit}
              handlingbet={handlingbet}
              betReset={betReset}
              onClose={handleClose}
              betSubmitConform={betSubmitConform}
            />
          }
        />
      }

      <div style={{ margin: '10px 0px' }}>
        <BackButton onClick={handleClick} />
      </div>
      <div className="dragon-tiger">
        <div className="row">
          <div className="col-md-8">
            <div className="luckyseven">
              <div className="teenpatti-header ">

                <div className='cmc'>
                  <span style={{ fontWeight: '500', marginRight: '10px', fontSize: "14px" }}>
                    Andar Bahar
                  </span>
                  <span style={{ fontSize: "14px" }}>
                    Round ID: {tableroundId || 0} <br />
                  </span>
                </div>
                <div className='cmc' style={{ fontSize: '14px' }}>
                  <span style={{ color: '' }} > Date: {getISTDate()}</span>
                  <span style={{ color: '' }} > Time: {getISTTime()}</span>

                </div>

              </div>
              <div className="live_stream">
                <div className="main-websocket">
                  <Cassinoifram id={3053} />
                </div>
                <div className="overflow-card-section">
                  <div className="stream-card-section">
                    <p>Andar</p>
                    <div className="cmd andarbaharscroll">
                      {cardValues &&
                        cardValues.map((res) => {
                          // Find the corresponding item in teenpattilive
                          const matchingItem = teenpattilive.find(
                            (item) => res === item.value
                          );

                          return (
                            <div style={{}} key={res.value}>
                              {matchingItem && (
                                <img
                                  className="card-image"
                                  src={matchingItem.card}
                                  alt={`Card ${res}`}
                                />
                              )}
                            </div>
                          );
                        })}
                    </div>
                  </div>
                  <div>
                    <p>Bahar</p>
                    <div className="cmd andarbaharscroll">
                      {cardValues &&
                        cardValues2.map((res) => {
                          const matchingItem = teenpattilive.find(
                            (item) => res === item.value
                          );

                          return (
                            <div key={res.value}>
                              {matchingItem && (
                                <img
                                  className="card-image"
                                  src={matchingItem.card}
                                  alt={`Card ${res}`}
                                />
                              )}
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </div>
                <div className="overflow-casino-timer">
                  <FlipCountdown endAt={timmer} />
                </div>
              </div>


              <div style={{ margin: "0px" }} className="row">
                <div className="select_bet_box mt-2">
                  <h5 className="tc">ANDAR</h5>
                  <div className="mt-2 col-12  d-flex justify-content-center cardssection">
                    <div className="card-grid">

                      {andraTableCardId != [] ?
                        mappedCardsAndarTable?.map((card, index) => (
                          <div
                            // onClick={card.data?.gstatus !== "0" && handelPlacebet(card?.data)}
                            key={index} className="card-container">
                            <img
                              className="card-image"
                              src={card.card}
                              alt={`Card ${index}`}
                            />
                            <div className="card-text">00</div>
                          </div>
                        )) :
                        cardsimgAndarPlacebet?.map((card, index) => (
                          <div onClick={() => {
                            card.data?.gstatus !== "0" && handelPlacebet(card.data);
                          }}

                            key={index} className="card-container">
                            <img
                              className="card-image"
                              src={card.card}
                              alt={`Card ${index}`}
                            />
                            <div className="card-text">00</div>
                          </div>
                        ))

                      }
                    </div>
                  </div>
                </div>
                <div className="select_bet_box mt-2">
                  <h5 className="tc">BAHAR</h5>
                  <div className="mt-2 col-12  d-flex justify-content-center cardssection">
                    <div className="card-grid">
                      {BaharTableCardId != [] ?

                        mappedCardsBaharTable?.map((card, index) => (
                          <div

                            key={index} className="card-container">
                            <img
                              className="card-image"
                              src={card.card}
                              alt={`Card ${index}`}
                            />
                            <div className="card-text">00</div>
                          </div>
                        )) :
                        cardsimgBaharPlacebet?.map((card, index) => (
                          <div onClick={() => {
                            card.data?.gstatus !== "0" && handelPlacebet(card.data);
                          }}

                            key={index} className="card-container">
                            <img
                              className="card-image"
                              src={card.card}
                              alt={`Card ${index}`}
                            />
                            <div className="card-text">00</div>
                          </div>
                        ))
                      }
                    </div>
                  </div>
                </div>
              </div>

              <div className="last_result  cmc">
                <span>Last Result</span>
                <span>View Result</span>
              </div>
            </div>
          </div>

          <div className="teenpatti-betsection col-md-4">
          {betlist.length != 0 && 

<div>
<div className="place-bet-h">Open Bet</div>
              <CompletedBetList columns={columnsopenbet} listData={notCompletedBetList} />
            </div>
}
 {checkProfitLoss(betlist)?.totalAmount !== 0 &&
            <div>
              <div className="place-bet-h cmc">
                <div>Completed Bet List</div>
                <div
                  style={{
                    color: checkProfitLoss(betlist)?.isProfit
                      ? "yellow"
                      : "red",
                  }}
                >
                  P/L: {checkProfitLoss(betlist).profitLossAmount}
                </div>
              </div>
              <CompletedBetList columns={columns} listData={completedBetList} />
            </div>
}
          </div>
        </div>
      </div>

      <div style={{ marginTop: "10px" }}>
        <BackButton onClick={handleClick} />
      </div>
    </>
  );
};
export default AndarBahar;
