import React, { useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import TableRow from "@mui/material/TableRow";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";

const CommanTable = ({
  title,
  tabletool,
  columns,
  totalDataLength,
  rows,
  onPageChange,
  onRowsPerPageChange,
  page,
  rowsPerPage,
  options,
  searchCallBack,
  searchvalue,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [searchKey, setSearchKey] = useState("");

  const isMenuOpen = Boolean(anchorEl);

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleChangePage = (event, newPage) => {
    onPageChange && onPageChange(event, newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    onRowsPerPageChange && onRowsPerPageChange(event, newRowsPerPage);
  };

  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id="primary-search-account-menu"
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
      <MenuItem onClick={handleMenuClose}>My account</MenuItem>
      <MenuItem onClick={handleMenuClose}>Logout</MenuItem>
    </Menu>
  );

  return (
    <>
      <Paper sx={{ width: "100%", overflow: "hidden", height: "80%" }}>
        {!options?.hidetablehead && (
          <Toolbar
            sx={{
              pl: { sm: 2 },
              pr: {
                xs: 1,
                sm: 1,
              },
              justifyContent: "space-between",
              background: "#fff",
            }}
          >
            <div className="table-head-bar">
              <h1 className="table-head-title">{title}</h1>
              {!options?.hideSearchBar && (
                <>
                  <i className="las la-search"></i>

                  <form>
                    <input
                      type="search"
                      placeholder="Search..."
                      value={searchKey}
                      onChange={(e) => {
                        setSearchKey(e.target.value);
                        if (searchCallBack) {
                          searchCallBack(e.target.value);
                        }
                      }}
                    />
                  </form>
                </>
              )}
            </div>

            <Typography>
              {/* tabletool  */}
              {tabletool && tabletool({})}
            </Typography>
          </Toolbar>
        )}
        <TableContainer sx={{ maxHeight: 540 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    sx={{ border: "1px solid grey" }}
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows &&
                rows?.map((row, index) => (
                  <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                    {columns.map((column) => (
                      <TableCell
                        sx={{ border: "1px solid #ccc" }}
                        key={column.id}
                        align={column.align}
                      >
                        {column?.rendor
                          ? column?.rendor(row)
                          : column.format && typeof row[column.id] === "number"
                          ? column.format(row[column.id])
                          : row[column.id]}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        {!options?.hidePagination && (
          <TablePagination
            rowsPerPageOptions={[50, 100, 150]}
            component="div"
            count={totalDataLength || 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      </Paper>
      {renderMenu}
    </>
  );
};

CommanTable.defaultProps = {
  rowsPerPageOptions: [50, 100, 150],
};

export default CommanTable;
