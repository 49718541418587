import { useForm } from "react-hook-form";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { home, login } from "../../routesconfig/constant";
import { BackButton } from "../common/backbutton";
import { ChangePassword } from "../../api/login/useLogin";


const ChangeClientPassword = () => {
    const handleClick =()=>{
        navigate(home)
    }

  
    
    const authToken = localStorage.getItem('authToken')
    const navigate = useNavigate();
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authToken}`
    };


    const { response, loading, submit } = ChangePassword();   
    const onSubmit = (data) => {
        debugger
      submit(data, (response) => { 
        navigate(home);
  
      });
    };

    
    return (
        <>
         <BackButton onClick={handleClick}/>
        <div className="home-option-card">

            <Box
                className="loginBox"
                component="form"
                onSubmit={handleSubmit(onSubmit)}
                sx={{marginTop:'10px'}}
            >

                <Typography variant="h5" component="div" sx={{ mb: 2,textAlign:'center' }}>
                    Change Password
                </Typography>

                <TextField
                    fullWidth
                    label="Old Password"
                    {...register("oldPassword", {
                        required: "Old Password is required",
                        minLength: {
                            value: 1,
                            message: "Username must be at least 3 characters",
                        },
                    })}
                    error={Boolean(errors.oldPassword)}
                    helperText={errors.oldPassword?.message}
                    margin="normal"
                />

                <TextField
                    fullWidth
                    // type="newPassword"
                    label="Enter New Password"
                    {...register("newPassword", {
                        required: "New Password is required",
                        minLength: {
                            value: 6,
                            message: "Password must be at least 6 characters",
                        },
                    })}
                    error={Boolean(errors.newPassword)}
                    helperText={errors.newPassword?.message}
                    margin="normal"
                    sx={{ mt: 2 }}
                />


                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                    sx={{ mt: 2,backgroundColor:'#009270' }}
                >
                    Change Password
                </Button>

            </Box>
        </div>
        </>
    );
};

export default ChangeClientPassword;
