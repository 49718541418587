import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CommanTable from "../../common/commantable";
import { roundToDecimals } from "../../../service/utilities/roundDecimal";
import { TransactionLedgerType } from "../../../service/model";
import {
  formatDate,
  formatDateTime,
} from "../../../service/utilities/dateFormater";
import { UseLedgerapi } from "../../../api/login/useLogin";
import { BackButton } from "../../common/backbutton";
import { home } from "../../../routesconfig/constant";
import CompletedBetList from "../../casinoplay/utilities/casinoTable";
import { GetFancyresult } from "../../../api/sassinmatch/sassionbet";
import {
  CasinobetListbydate,
  GetcasinoBetList,
} from "../../../api/cassino/cassinogame";
import { Button } from "@mui/material";

const CompleteGame = () => {
  const navigate = useNavigate();
  const ledgerdata = JSON.parse(sessionStorage.getItem("ledgerdata") || "{}");

  const userId = sessionStorage.getItem("userid");

  const { submit: getledgerData } = UseLedgerapi();

  const [dateObj, setDateObj] = useState({ startDate: null, endDate: null });
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [searchKey, setSearchKey] = useState("");

  const [agenttabledata, setAgentTabledata] = useState([]);
  const [rejectbet, setRejectBet] = useState([]);
  const [totalDataLength, setTotalDataLength] = useState(false);
  const [totaldatacount, setTabledataCount] = useState();
  const [credittotal, setCreditTotal] = useState(0);
  const [debittotal, setDebitTotal] = useState(0);

  const pagination = {};

  console.log(ledgerdata);

  // const fatchStatementData = (id) => {

  //   getledgerData(pagination,
  //     (response) => {
  //       const agenttabledata = response?.data?.records?.map(
  //         (res, index) => ({
  //           ...res,
  //           createdDate: formatDate(res.createdDate),
  //           credit: (res.amount < 0 ? Math.abs(roundToDecimals(res.amount)) : 0),
  //           debit: (res.amount > 0 ? (roundToDecimals(res.amount)) : 0),
  //           balance: (Math.abs(roundToDecimals(res.balance))),
  //           ledgerType: (TransactionLedgerType.find(item => item.value === res.ledgerType)).name

  //         })
  //       );
  //       setTabledataCount(agenttabledata.length);
  //       setTotalDataLength(response?.data?.totalRecords);
  //       setCreditTotal(Math.abs(response?.data?.credit))
  //       setDebitTotal(Math.abs(response?.data?.debit))
  //     },
  //     { currentRecordsCount: id }
  //   )
  // }
  // useEffect(() => {
  //   fatchStatementData()
  // }, [page, rowsPerPage, dateObj, searchKey]);

  const columns = [
    { id: "selection", label: "Casino Game", align: "center" },
    { id: "date", label: "DATE", align: "center" },
    { id: "rate", label: "RATE", align: "center" },
    { id: "amount", label: "AMOUNT", align: "center" },
    { id: "mode", label: "MODE", align: "center" },
    { id: "result", label: "SHOW RESULT", align: "center" },
    { id: "profitAndLoss", label: "P&L", align: "center" },
  ];
  const columns2 = [
    { id: "oddType", label: "Odd Type" },
    { id: "session", label: "Runner" },
    { id: "date", label: "Date" },
    { id: "rate", label: "Rate" },
    { id: "run", label: "Run" },
    { id: "result", label: "Result" },
    { id: "amount", label: "Amount" },
    { id: "mode", label: "Mode" },
    { id: "profitAndLoss", label: "P/L" },
    { id: "Remark", label: "remark" },
  ];

  const columns3 = [
    { id: "oddType", label: "Odd Type" },
    { id: "session", label: "Runner" },
    { id: "date", label: "Date" },
    { id: "rate", label: "Rate" },
    { id: "run", label: "Run" },
    { id: "result", label: "Result" },
    { id: "amount", label: "Amount" },
    { id: "mode", label: "Mode" },
    { id: "profitAndLoss", label: "P/L" },
  ];

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    debugger;
    setRowsPerPage(parseInt(event?.target?.value || 10, 10));
    setPage(0); // Reset to first page whenever rows per page changes
  };

  const onDateChange = (dateObject) => {
    setDateObj(dateObject);
    handleRowsPerPageChange();
  };

  // const handletableSeeMore = () => {
  //   fatchStatementData(totaldatacount)
  // }
  const params = {
    gameType: ledgerdata?.eventId,
    date: ledgerdata?.date,
  };

  const { response: casinoResestList, refetch: casinoRefetchList } =
    CasinobetListbydate(params);
  const betlist = casinoResestList?.data || [];

  const completedBetList = casinoResestList?.data
    ?.filter((res) => res.completed)
    ?.map((res) => ({
      ...res,
      date: formatDateTime(res?.createdDateTime),
      mode: res?.win === false ? "Loss" : "Profit",

      // "createdDateTime": "2024-11-28T22:28:55.8933333",
      //             "win": false,
      //             "completed": true,
      //             "completedDateTime": "2024-11-28T22:31:59.4666667"
    }));

  const { response: fancyResult, refetch: fancyRefetch } = GetFancyresult(
    ledgerdata?.eventId
  );

  useEffect(() => {
    if (fancyResult?.data) {
      const data1 = fancyResult.data.filter((item) => item.completed === true);
      console.log(data1);

      const updatedData = data1?.map((item) => ({
        ...item,
        date: formatDate(item?.createdDateTime), // Format the date property
      }));

      // Update the state
      setAgentTabledata(updatedData);
    }
  }, [fancyResult]); // Add dependencies to ensure the effect runs only when `fancyResult` changes

  const tabletool = () => {};
  const handleClick = () => {
    navigate(home);
  };
  const matchdata = [
    {
      header: (
        <div>
          Match <span style={{ color: "blue" }}>Plus</span>{" "}
          <span style={{ color: "red" }}>Minus</span>
        </div>
      ),
      answer: (
        <span style={{ color: ledgerdata?.matchAmount < 0 ? "red" : "blue" }}>
          {ledgerdata?.matchAmount}
        </span>
      ),
    },
    {
      header: (
        <div>
          Fancy <span style={{ color: "blue" }}>Plus</span>{" "}
          <span style={{ color: "red" }}>Minus</span>
        </div>
      ),
      answer: (
        <span
          style={{ color: ledgerdata?.sessionAmount < 0 ? "red" : "blue" }}
        >
          {ledgerdata?.sessionAmount}
        </span>
      ),
    },

    {
      header: (
        <div>
          Session <span style={{ color: "yellowGreen" }}>Commission</span>
        </div>
      ),
      answer: (
        <span style={{ color: "yellowGreen" }}>
          {ledgerdata?.sessionCommission}
        </span>
      ),
    },
    {
      header: (
        <div>
          Match <span style={{ color: "yellowGreen" }}>Commission</span>
        </div>
      ),
      answer: (
        <span style={{ color: "yellowGreen" }}>
          {ledgerdata?.matchCommission}
        </span>
      ),
    },
    {
      header: (
        <div>
          Total <span style={{ color: "yellowGreen" }}>Commission</span>
        </div>
      ),
      answer: (
        <span style={{ color: "yellowGreen" }}>{ledgerdata?.commission}</span>
      ),
    },
    {
      header: (
        <div>
          Net <span style={{ color: "blue" }}>Plus</span>{" "}
          <span style={{ color: "red" }}>Minus</span>
        </div>
      ),
      answer: (
        <span style={{ color: ledgerdata?.amount < 0 ? "red" : "blue" }}>
          {ledgerdata?.amount}
        </span>
      ),
    },
  ];

  const casiondata = [
    {
      header: (
        <div>
          Casino <span style={{ color: "blue" }}>Plus</span>{" "}
          <span style={{ color: "red" }}>Minus</span>
        </div>
      ),
      answer: 

      (
        <span style={{ color: ledgerdata?.matchAmount < 0 ? "red" : "blue" }}>
          {ledgerdata?.matchAmount}
        </span>
      ),
    },
    {
      header: (
        <div>
          Total <span style={{ color: "yellowGreen" }}>Commission</span>
        </div>
      ),
      answer: 
      (
        <span style={{ color: "yellowgreen" }}>
          {ledgerdata?.commission}
        </span>
      ),
    },
    {
      header: (
        <div>
          Net <span style={{ color: "blue" }}>Plus</span>{" "}
          <span style={{ color: "red" }}>Minus</span>
        </div>
      ),
      answer: 
      (
        <span style={{ color: ledgerdata?.amount < 0 ? "red" : "blue" }}>
          {ledgerdata?.amount}
        </span>
      ),
    },
  ];

  return (
    <>
      {/* <div className="lanedane-container">
        <div className="row">
          <div className="col-md-4">
            <div style={{ backgroundColor: "#dd2727bd" }} className="items">
              <span>Lena</span>
              <span>{roundToDecimals(credittotal)}</span>
            </div>


          </div>
          <div className="col-md-4 ">
            <div style={{ backgroundColor: "#3ba146" }} className="items">
              <span>Dena</span>
              <span>{roundToDecimals(debittotal)}</span>
            </div>

          </div>


        </div>
      </div> */}
      <Button
        sx={{
          width: "100%",
        }}
      >
        <div className="tc" style={{ color: "white", width: "100%" }}>
          {ledgerdata.ledgerType === 2 ? "Match Bets" : "Casino Bets"}
        </div>
      </Button>
      {ledgerdata.ledgerType === 2 ? (
        <div className="mt-3">
          <CommanTable
            title={"Match Bets"}
            columns={columns3}
            rows={agenttabledata}
            totalDataLength={totalDataLength}
            page={page}
            rowsPerPage={rowsPerPage}
            options={{
              hidePagination: true,
              hidetablehead: true,
            }}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleRowsPerPageChange}
            tabletool={tabletool}
            searchCallBack={(e) => {
              setSearchKey(e);
            }}
          />
        </div>
      ) : (
        <div className="mt-3">
          <CommanTable
            title={"Match Bets"}
            columns={columns}
            rows={completedBetList}
            totalDataLength={totalDataLength}
            page={page}
            rowsPerPage={rowsPerPage}
            options={{
              hidePagination: true,
              hidetablehead: true,
            }}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleRowsPerPageChange}
            tabletool={tabletool}
            searchCallBack={(e) => {
              setSearchKey(e);
            }}
          />
        </div>
      )}
      {ledgerdata.ledgerType === 2 && (
        <div>
          <div className="teenpatti-betlist-container mt-3">
            <table className="table table-bordered text-center mb-0">
              <tbody>
                {matchdata.map((item, index) => (
                  <tr key={index}>
                    <td>
                      <strong>{item.header}</strong>
                    </td>
                    <td>{item.answer}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
      {ledgerdata.ledgerType === 3 && (
        <div>
          <div className="teenpatti-betlist-container">
            <table className="table table-bordered text-center mb-0">
              <tbody>
                {casiondata.map((item, index) => (
                  <tr key={index}>
                    <td>
                      <strong>{item.header}</strong>
                    </td>
                    <td>{item.answer}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}

      {ledgerdata.rejected === true && (
        <div className="mt-3">
          <CommanTable
            title={"Reject Bet Bets"}
            columns={columns2}
            rows={rejectbet}
            totalDataLength={totalDataLength}
            page={page}
            rowsPerPage={rowsPerPage}
            options={{
              hidePagination: true,
              hidetablehead: true,
            }}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleRowsPerPageChange}
            tabletool={tabletool}
            searchCallBack={(e) => {
              setSearchKey(e);
            }}
          />
        </div>
      )}
      <div className="mt-2">
        <BackButton onClick={handleClick} />
      </div>
    </>
  );
};
export default CompleteGame;
