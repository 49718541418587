import logimg from '../../assets/images/log-out.png';
import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { home, login } from '../../routesconfig/constant';
import { useDispatch, useSelector } from 'react-redux';
import logo from '../../assets/images/bingobet.webp'
import LogoutIcon from '@mui/icons-material/Logout';
import Dropdown from '../inputfields';
import { setTheme } from '../../store/feature/authSlice';
import UseTheme from '../../api/profile/useTheme';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { IconButton, Tooltip } from '@mui/material';
import { Ballot, PersonOffOutlined, WaterDrop } from '@mui/icons-material';
import MenuIcon from '@mui/icons-material/Menu';
const Header = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const userDetails = useSelector(state => state.auth.userdetail);


  return (
    <>
      <div className="cmc header">
        <div style={{ width: '33%' }} className=''>
         <Link to={home}> < span style={{ fontSize: '15px', fontWeight: '600',color:"white" }} >BINGOBET<b style={{ color: 'gold', fontSize: '18px' }}>247</b></span></Link>
        </div>

        <div style={{ width: '33%', textAlign: 'center' }} className="header-contant">
          <span style={{ fontSize: '12px', }}>
            {userDetails && userDetails.name }
          </span>
          <span style={{ fontSize: "10px" }}>
            Coins:   {userDetails && userDetails.chips }
          </span>

          <span style={{ fontSize: "10px" }}>
            Exposure:  {userDetails && userDetails.exposure }
          </span>
        </div>





        <div style={{ width: '33%', textAlign: 'end' }} className="img-field ">
          <BasicMenu />
          {/* <LogoutIcon
            sx={{ color: 'white', fontSize: '35px' }}
          /> */}
        </div>
      </div>
      <header className="notification-header">
        <div className="notification-text">
          प्रिय ग्राहक एडवांस सेशन और टॉस का रेट चालू है धन्यवाद !
        </div>
      </header>
    </>
  );
};

export default Header;





export function BasicMenu() {
  const myTheme = useSelector(state => state.auth.theme);

  // useEffect(() => {
  //   themeRefetch()
  // }, [myTheme])

  const { refetch: themeRefetch, response } = UseTheme(myTheme);

  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };


  const handleLogOut = () => {
    localStorage.removeItem("userDetails");
    localStorage.removeItem('authToken');
    window.location.href = login

  };

  const handleTheme = (value) => {
    dispatch(setTheme(value))
    localStorage.setItem('theme',value)
  }

  return (
    <div>
      <IconButton
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >

        <MenuIcon sx={{ color: "white !important" }} />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem sx={{
       display:'flex',
       justifyContent:'center',
       alignItems:'center'
        }}
        
        onClick={handleLogOut}><LogoutIcon/></MenuItem>
        <MenuItem sx={{ display: 'flex', flexDirection: "column" }}>



            <IconButton className='mt-2' onClick={() => { handleTheme('light') }}
              sx={{ border: "1px solid green", backgroundColor: "white !important" }}>
              <WaterDrop sx={{ color: "green" }} />
            </IconButton>

            <IconButton className='mt-2' onClick={() => { handleTheme('green') }}
              sx={{ border: "1px solid green", backgroundColor: "black !important" }}>
              <WaterDrop sx={{ color: "green" }} />
            </IconButton>

            <IconButton className='mt-2' onClick={() => { handleTheme('red') }}
              sx={{ border: "1px solid green", backgroundColor: "black !important" }}>
              <WaterDrop sx={{ color: "red" }} />
            </IconButton>

            <IconButton className='mt-2' onClick={() => { handleTheme('orange') }}
              sx={{ border: "1px solid green", backgroundColor: "black !important" }}>
              <WaterDrop sx={{ color: "orange" }} />
            </IconButton>

            <IconButton className='mt-2' onClick={() => { handleTheme('yellow') }}
              sx={{ border: "1px solid green", backgroundColor: "black !important" }}>
              <WaterDrop sx={{ color: "yellow" }} />
            </IconButton>

            <IconButton className='mt-2' onClick={() => { handleTheme('blue') }}
              sx={{ border: "1px solid green", backgroundColor: "black !important" }}>
              <WaterDrop sx={{ color: "blue" }} />
            </IconButton>


        </MenuItem>
      </Menu>
    </div>
  );
}
