import { useForm } from "react-hook-form";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import { home, instruction } from "../../routesconfig/constant";
import { useDispatch } from "react-redux";
import { setToken, setUserDetail } from "../../store/feature/authSlice";
import GetCaller from "../../service/apiServices";
import { LoginApi, Userdetails } from "../../service/apiconst";
import UseLogin from "../../api/login/useLogin";
import { InputAdornment } from "@mui/material";
import FlipCountdown from "../common/flipcountdown";

const LoginForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  localStorage.removeItem("userDetails");

  const fetchUserDetails = (authToken) => {
    GetCaller({
      API: Userdetails,
      dispatch: (e) => { },
      item: authToken,
      callback: ({ response }) => {
        const userdetails = response?.data?.data;
        dispatch(setUserDetail(userdetails));
        localStorage.setItem("userDetails", JSON.stringify(userdetails));
      },
    });
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const { response, loading, submit } = UseLogin();

  const onSubmit = (data) => {
    const datanew = {
      ...data, // Spread the original data
      userName: `C${data.userName}`, // Prepend 'U' to the userName
    };
    submit(datanew, (response) => {
      const authToken = response?.data?.authToken;
      localStorage.setItem("authToken", authToken);
      dispatch(setToken(authToken));
      fetchUserDetails(authToken);
      navigate(instruction);
    });
  };

  return (
    <div className="loginPage">
      <Box
        className="loginBox"
        component="form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="cmd">
          <Typography
            variant="h5"
            component="div"
            sx={{
              color: "#009270",
              fontSize: "30px",
              fontWeight: "600",
              marginTop: "5px",
              fontFamily: "sans-serif",
              textAlign: "center",
            }}
          >
            BINGOBET
          </Typography>

          <Typography
            variant="h5"
            component="div"
            sx={{
              color: "#ffd700",
              fontSize: "45px",
              fontWeight: "600",
              textAlign: "center",
            }}
          >
            247
          </Typography>
        </div>
        <div className="logintext">
          {" "}
          <Typography
            variant="h5"
            component="div"
            sx={{
              color: "",
              fontFamily: "sans-serif",
              textAlign: "center",
              fontWeight: "600",
            }}
          >
            Login Here
          </Typography>
        </div>

        <div style={{ position: "relative" }}>
          <TextField
            fullWidth
            type="number"
            label="Username/Code"
            {...register("userName", {
              required: "Username is required",
              minLength: {
                value: 3,
                message: "Username must be at least 3 characters",
              },
            })}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start" sx={{ color: "blue" }}>
                  C
                </InputAdornment>
              ),
            }}
            error={Boolean(errors.userName)}
            helperText={errors.userName?.message}
            margin="normal"
            sx={{
              mt: 2,
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderRadius: "10px !important",
                  borderColor: "#009270",
                  ":focus": {
                    borderColor: "red",
                  },
                },
              },
            }}
          />

          {/* <div  style={{position:'absolute', backgroundColor:'red',top:'22px',padding:'14px 12px'}}>C</div> */}
        </div>

        <TextField
          fullWidth
          type="password"
          label="Password"
          {...register("password", {
            required: "Password is required",
            minLength: {
              value: 6,
              message: "Password must be at least 6 characters",
            },
          })}
          error={Boolean(errors.password)}
          helperText={errors.password?.message}
          margin="normal"
          sx={{
            mt: 2,
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderRadius: "10px !important",
                borderColor: "#009270",
              },
            },
          }}
        />

        <FormControlLabel
          control={<Checkbox {...register("rememberMe")} color="primary" />}
          label="Remember Me"
          sx={{ mt: 1, textAlign: "left", color: "" }}
        />

        <Button
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
          sx={{
            mt: 2,
            backgroundColor: "#009270",
            padding: "10px 0px",
            borderRadius: "20px",
          }}
        >
          Login
        </Button>
        <Typography
          variant="p"
          component="div"
          sx={{
            marginTop: "40px",
            color: "#4a4a4a",
            fontSize: "14px",
            fontWeight: "400",
            fontFamily: "sans-serif",
            textAlign: "center",
          }}
        >
          Copyright ©Bingobet247.net 2024.
        </Typography>
      </Box>
    </div>
  );
};

export default LoginForm;
