
import card1 from "../assets/teen20/1.png";
import card2DD from "../assets/teen20/2DD.png";
import card3DD from "../assets/teen20/3DD.png";
import card4DD from "../assets/teen20/4DD.png";
import card5DD from "../assets/teen20/5DD.png";
import card6DD from "../assets/teen20/6DD.png";
import card7DD from "../assets/teen20/7DD.png";
import card8DD from "../assets/teen20/8DD.png";
import card9DD from "../assets/teen20/9DD.png";
import card10DD from "../assets/teen20/10DD.png";
import cardJDD from "../assets/teen20/JDD.png";
import cardQDD from "../assets/teen20/QDD.png";
import cardKDD from "../assets/teen20/KDD.png";
import cardADD from "../assets/teen20/ADD.png";

import card2CC from "../assets/teen20/2CC.png";
import card3CC from "../assets/teen20/3CC.png";
import card4CC from "../assets/teen20/4CC.png";
import card5CC from "../assets/teen20/5CC.png";
import card6CC from "../assets/teen20/6CC.png";
import card7CC from "../assets/teen20/7CC.png";
import card8CC from "../assets/teen20/8CC.png";
import card9CC from "../assets/teen20/9CC.png";
import card10CC from "../assets/teen20/10CC.png";
import cardJCC from "../assets/teen20/JCC.png";
import cardQCC from "../assets/teen20/QCC.png";
import cardKCC from "../assets/teen20/KCC.png";
import cardACC from "../assets/teen20/ACC.png";

import card2SS from "../assets/teen20/2SS.png";
import card3SS from "../assets/teen20/3SS.png";
import card4SS from "../assets/teen20/4SS.png";
import card5SS from "../assets/teen20/5SS.png";
import card6SS from "../assets/teen20/6SS.png";
import card7SS from "../assets/teen20/7SS.png";
import card8SS from "../assets/teen20/8SS.png";
import card9SS from "../assets/teen20/9SS.png";
import card10SS from "../assets/teen20/10SS.png";
import cardJSS from "../assets/teen20/JSS.png";
import cardQSS from "../assets/teen20/QSS.png";
import cardKSS from "../assets/teen20/KSS.png";
import cardASS from "../assets/teen20/ASS.png";

import card2HH from "../assets/teen20/2HH.png";
import card3HH from "../assets/teen20/3HH.png";
import card4HH from "../assets/teen20/4HH.png";
import card5HH from "../assets/teen20/5HH.png";
import card6HH from "../assets/teen20/6HH.png";
import card7HH from "../assets/teen20/7HH.png";
import card8HH from "../assets/teen20/8HH.png";
import card9HH from "../assets/teen20/9HH.png";
import card10HH from "../assets/teen20/10HH.png";
import cardJHH from "../assets/teen20/JHH.png";
import cardQHH from "../assets/teen20/QHH.png";
import cardKHH from "../assets/teen20/KHH.png";
import cardAHH from "../assets/teen20/AHH.png";

export const teenpattilive = [
  {
    card: card1,
    value: "1",
  },

  {
    card: card2DD,
    value: "2DD",
  },
  {
    card: card3DD,
    value: "3DD",
  },
  {
    card: card4DD,
    value: "4DD",
  },
  {
    card: card5DD,
    value: "5DD",
  },
  {
    card: card6DD,
    value: "6DD",
  },
  {
    card: card7DD,
    value: "7DD",
  },
  {
    card: card8DD,
    value: "8DD",
  },
  {
    card: card9DD,
    value: "9DD",
  },
  {
    card: card10DD,
    value: "10DD",
  },
  {
    card: cardJDD,
    value: "JDD",
  },

  {
    card: cardQDD,
    value: "QDD",
  },
  {
    card: cardKDD,
    value: "KDD",
  },
  {
    card: cardADD,
    value: "ADD",
  },
  {
    card: card2HH,
    value: "2HH",
  },
  {
    card: card3HH,
    value: "3HH",
  },
  {
    card: card4HH,
    value: "4HH",
  },
  {
    card: card5HH,
    value: "5HH",
  },
  {
    card: card6HH,
    value: "6HH",
  },
  {
    card: card7HH,
    value: "7HH",
  },
  {
    card: card8HH,
    value: "8HH",
  },
  {
    card: card9HH,
    value: "9HH",
  },
  {
    card: card10HH,
    value: "10HH",
  },
  {
    card: cardJHH,
    value: "JHH",
  },

  {
    card: cardQHH,
    value: "QHH",
  },
  {
    card: cardKHH,
    value: "KHH",
  },
  {
    card: cardAHH,
    value: "AHH",
  },
  {
    card: card2SS,
    value: "2SS",
  },
  {
    card: card3SS,
    value: "3SS",
  },
  {
    card: card4SS,
    value: "4SS",
  },
  {
    card: card5SS,
    value: "5SS",
  },
  {
    card: card6SS,
    value: "6SS",
  },
  {
    card: card7SS,
    value: "7SS",
  },
  {
    card: card8SS,
    value: "8SS",
  },
  {
    card: card9SS,
    value: "9SS",
  },
  {
    card: card10SS,
    value: "10SS",
  },
  {
    card: cardJSS,
    value: "JSS",
  },

  {
    card: cardQSS,
    value: "QSS",
  },
  {
    card: cardKSS,
    value: "KSS",
  },
  {
    card: cardASS,
    value: "ASS",
  },

  {
    card: card2CC,
    value: "2CC",
  },
  {
    card: card3CC,
    value: "3CC",
  },
  {
    card: card4CC,
    value: "4CC",
  },
  {
    card: card5CC,
    value: "5CC",
  },
  {
    card: card6CC,
    value: "6CC",
  },
  {
    card: card7CC,
    value: "7CC",
  },
  {
    card: card8CC,
    value: "8CC",
  },
  {
    card: card9CC,
    value: "9CC",
  },
  {
    card: card10CC,
    value: "10CC",
  },
  {
    card: cardJCC,
    value: "JCC",
  },

  {
    card: cardQCC,
    value: "QCC",
  },
  {
    card: cardKCC,
    value: "KCC",
  },
  {
    card: cardACC,
    value: "ACC",
  },
];


export const TransactionLedgerType = [
  { name: "ALL", value: 4 },   
  { name: 'CASE', value: 1 },
  { name: "CASINO", value: 3 },   
  { name: 'CRICKET', value: 2 }, 
 ]