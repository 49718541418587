import card1 from "../../assets/images/cardimage/1.jpg";
import card2 from "../../assets/images/cardimage/2.jpg";
import card3 from "../../assets/images/cardimage/3.jpg";
import card4 from "../../assets/images/cardimage/4.jpg";
import card5 from "../../assets/images/cardimage/5.jpg";
import card6 from "../../assets/images/cardimage/6.jpg";
import card7 from "../../assets/images/cardimage/7.jpg";
import card8 from "../../assets/images/cardimage/8.jpg";
import card9 from "../../assets/images/cardimage/9.jpg";
import card10 from "../../assets/images/cardimage/10.jpg";
import cardJ from "../../assets/images/cardimage/11.jpg";
import cardQ from "../../assets/images/cardimage/12.jpg";
import cardK from "../../assets/images/cardimage/13.jpg";
import heart from "../../assets/images/cardimage/heartcard.png";
import shade from "../../assets/images/cardimage/shadecard.png";
import diamond from "../../assets/images/cardimage/diamondcard.png";
import club from "../../assets/images/cardimage/clubcard.png";
import check from "../../assets/images/check.png";
import cardA1 from "../../assets/images/cardimage/1.jpg";
import cardA2 from "../../assets/images/cardimage/2.jpg";
import cardA3 from "../../assets/images/cardimage/3.jpg";
import cardB1 from "../../assets/images/cardimage/4.jpg";
import cardB2 from "../../assets/images/cardimage/5.jpg";
import cardB3 from "../../assets/images/cardimage/6.jpg";
import { useEffect, useRef, useState } from "react";
import { BackButton } from "../common/backbutton";
import { casino, home } from "../../routesconfig/constant";
import { useNavigate } from "react-router-dom";
import {
  BetSectionDialog,
  ConfirmationDialog,
  ConfirmBetDialog,
} from "../common/dialog";
import LockIcon from "@mui/icons-material/Lock";
import { Cassinoifram } from "./gameIframe/casinoifram";
import SubmitCasinoBet, {
  FetchUserDetails,
  GetcasinoBetList,
  GetcasinoData,
  GetcasinoResult,
} from "../../api/cassino/cassinogame";
import { teenpattilive } from "../../service/model";
import PlacebetCasino from "../common/placebetcasino";
import moment from "moment-timezone";
import FlipCountdown from "../common/flipcountdown";
import { io } from "socket.io-client";
import { formatDate } from "../../service/utilities/dateFormater";
import { setUserDetail } from "../../store/feature/authSlice";
import { useDispatch } from "react-redux";
import CompletedBetList from "./utilities/casinoTable";
import { pollingValue } from "../../service/utilities/poolingutils";

const AmarAkbar = () => {
  const navigate = useNavigate();
  const welcomeRef = useRef(null);
  const [confirmbet, setBetConform] = useState(false);
  const dispatch = useDispatch();


  const scrollToContent = () => {
    if (welcomeRef.current) {
      welcomeRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    }
  };

  const [placebet, setPlacebet] = useState(false);
  const [stakeamount, setStakeAmount] = useState();
  const [betdata, setbetdata] = useState([]);
  const [aaabetResult, setaaaResult] = useState([]);
  const [aaadata, setAaaData] = useState([]);

  const betprofit = stakeamount * (betdata?.b1 - 1);

  const betConform = () => {
    setBetConform(true);
  };

  const handleClose = () => {
    setBetConform(false);
    setPlacebet(false);
  };
  const handelPlacebet = (value) => {
    setbetdata(value);
    scrollToContent();
    setPlacebet(true);
  };

  const handlingbet = (value) => {
    setStakeAmount(value);
  };
  const getISTDate = () => {
    return moment().tz("Asia/Kolkata").format("DD-MM-YYYY");
  };

  const getISTTime = () => {
    return moment().tz("Asia/Kolkata").format("HH:mm:ss");
  };

  const handleClick = () => {
    navigate(casino);
  };

  useEffect(() => {

    const intervalprofile = setInterval(Refetchprofile, 5000);
  }, []);
  const { response: responseprofile, refetch: Refetchprofile } =
    FetchUserDetails();
  const userdetails = responseprofile?.data;

  dispatch(setUserDetail(userdetails));

  const { response, loading, submit } = SubmitCasinoBet();

  const betSubmitConform = () => {
    const data = {
      gameType: "aaa",
      roundId: betdata?.mid,
      rate: betdata?.b1,
      amount: stakeamount,
      selection: betdata?.nat,
    };
    if (data.amount >= 100 && data.amount <= 25000) {
      submit(data, (response) => {
        if (response?.metaData?.resultMessage === "Success") {
          casinoRefetchList();
          betConform();
          Refetchprofile()
          setStakeAmount(0);
          handleClose()
        }
      });
    }
  };






  const SOCKET_SERVER_URL = "https://automaticapiserver.bbet247.net";
  const PASS_YOUR_EVENT_ID = "aaa"; // Replace with your actual event ID

  useEffect(() => {
    if (!pollingValue) {


      const socket = io(SOCKET_SERVER_URL, { transports: ["websocket"] });
      socket.on("connect", () => {

        socket.emit("addOnEVENT", PASS_YOUR_EVENT_ID);
      });

      socket.on("updatedData", (data) => {
        // Do work with your data

        setAaaData(data.data);

        setaaaResult(data.data.result)
      });

      // Error handling
      socket.on("connect_error", (err) => {
        console.error("Connection error:", err);
      });


      socket.on("error", (err) => {
        console.error("Socket error:", err);
      });



      // Cleanup function to disconnect the socket when the component unmounts
      return () => {
        socket.disconnect();
      };
    }
    {

      const interval = setInterval(casinoRefetch, 1000);
      return () => clearInterval(interval);
    }

  }, []);


  const { response: casinoResestList, refetch: casinoRefetchList } =
    GetcasinoBetList("aaa");
  const betlist = casinoResestList?.data || [];

  const completedBetList = casinoResestList?.data
    ?.filter((res) => res.completed)
    ?.map((res) => ({
      ...res,
      createdDateTime: formatDate(res?.createdDateTime),
    }));

  const notCompletedBetList = casinoResestList?.data
    ?.filter((res) => !res.completed)
    ?.map((res) => ({
      ...res,

    }));


  const { response: aaaData, refetch: casinoRefetch } = GetcasinoData("aaa");


  useEffect(() => {
    if (aaaData) {
      setaaaResult(aaaData?.data?.result);
    }
  }, [aaaData]);


  const aaavalue = pollingValue ? aaaData?.data?.data : aaadata?.data;

  const t1 = aaavalue?.t1;
  const timmer = t1 && t1[0]?.autotime;
  const C1 = teenpattilive.find((card) => t1 && card.value === t1[0].C1)?.card;
  const roundid = t1 && t1[0]?.mid;
  const tableroundId = (roundid && roundid.toString().split(".")[1]) || "0";
  const datat2 = aaavalue?.t2;

  const betReset = () => {
    setStakeAmount("");
  };

  let t2Amar,
    t2Akbar,
    t2Anthony,
    t2Even,
    t2Odd,
    t2Black,
    t2Red,
    t2Under7,
    t2Over7,
    t2CardA,
    t2Card2,
    t2Card3,
    t2Card4,
    t2Card5,
    t2Card6,
    t2Card7,
    t2Card8,
    t2Card9,
    t2Card10,
    t2CardJ,
    t2CardQ,
    t2CardK;

  if (Array.isArray(datat2)) {
    // Assign values inside the block without redeclaring
    t2Amar = datat2[0];
    t2Akbar = datat2[1];
    t2Anthony = datat2[2];
    t2Even = datat2[3];
    t2Odd = datat2[4];
    t2Red = datat2[5];
    t2Black = datat2[6];
    t2CardA = datat2[7];
    t2Card2 = datat2[8];
    t2Card3 = datat2[9];
    t2Card4 = datat2[10];
    t2Card5 = datat2[11];
    t2Card6 = datat2[12];
    t2Card7 = datat2[13];
    t2Card8 = datat2[14];
    t2Card9 = datat2[15];
    t2Card10 = datat2[16];
    t2CardJ = datat2[17];
    t2CardQ = datat2[18];
    t2CardK = datat2[19];
    t2Under7 = datat2[20];
    t2Over7 = datat2[21];
  }

  const cardsimgnew = [
    { name: card1, value: "Card 1", data: t2CardA || "" },
    { name: card2, value: "Card 2", data: t2Card2 || "" },
    { name: card3, value: "Card 3", data: t2Card3 || "" },
    { name: card4, value: "Card 4", data: t2Card4 || "" },
    { name: card5, value: "Card 5", data: t2Card5 || "" },
    { name: card6, value: "Card 6", data: t2Card6 || "" },
    { name: card7, value: "Card 7", data: t2Card7 || "" },
    { name: card8, value: "Card 8", data: t2Card8 || "" },
    { name: card9, value: "Card 9", data: t2Card9 || "" },
    { name: card10, value: "Card 10", data: t2Card10 || "" },
    { name: cardJ, value: "Card J", data: t2CardJ || "" },
    { name: cardQ, value: "Card Q", data: t2CardQ || "" },
    { name: cardK, value: "Card K", data: t2CardK || "" },
  ];

  const checkProfitLoss = (dataList) => {
    let data = {
      totalAmount: 0,
      winningAmount: 0,
      lossAmount: 0,
      profitLossAmount: 0,
      isProfit: true,
    };

    data.totalAmount = dataList
      .filter((bet) => bet.completed)
      .reduce((total, bet) => total + bet.amount, 0);

    data.winningAmount = dataList
      .filter((bet) => bet.completed && bet.win)
      .reduce((total, bet) => total + bet.profitAndLoss, 0);

    data.lossAmount = dataList
      .filter((bet) => bet.completed && !bet.win)
      .reduce((total, bet) => total + bet.profitAndLoss, 0);

    data.isProfit = data.winningAmount >= -data.lossAmount ? true : false;

    data.profitLossAmount = data.winningAmount + data.lossAmount;
    // ? data.winningAmount - data.lossAmount
    // : data.lossAmount - data.winningAmount

    return data;
  };

  useEffect(() => {
    if (timmer === "30") {
      Refetchprofile();
      casinoRefetchList();
    }
  }, [timmer]);

  useEffect(() => {
    if (t2Anthony?.gstatus === "SUSPENDED") {
      handleClose()
    }
  }, [aaavalue])



  const columns = [
    { id: "selection", label: "Bet" },
    { id: "rate", label: "Rate" },
    { id: "amount", label: "Amount" },
    { id: "result", label: "Result" },
    { id: "createdDateTime", label: "Date & Time" },
    { id: "profitAndLoss", label: "P/L" },
  ];


  const columnsopenbet = [
    { id: "selection", label: "Match Bet" },
    { id: "rate", label: "Odds" },
    { id: "amount", label: "Stake" },

  ];

  return (
    <>
      <div style={{ margin: "10px" }}>
        {" "}
        <BackButton onClick={handleClick} />{" "}
      </div>

      {t2Red?.gstatus !== "0" && (
        <BetSectionDialog
          onClose={handleClose}
          open={placebet}
          content={
            <PlacebetCasino
              placebet={placebet}
              setPlacebet={setPlacebet}
              setStake={setStakeAmount}
              stake={stakeamount}
              odds={betdata && betdata.b1}
              betnation={betdata && betdata.nat}
              betprofit={betprofit}
              handlingbet={handlingbet}
              betReset={betReset}
              onClose={handleClose}
              betSubmitConform={betSubmitConform}
            />
          }
        />
      )}

      <div className="casino_container amarakbar">
        <div className="row">
          <div className="col-md-8">
            <div className="luckyseven">
              <div className="teenpatti-header bg-blue p-1 t-white">
                <div className="cmc">
                  <span
                    style={{
                      fontWeight: "500",
                      marginRight: "10px",
                      fontSize: "14px",
                    }}
                  >
                    Amar Akbar Anth..
                  </span>
                  <span style={{ fontSize: "14px" }}>
                    Round ID: {tableroundId || 0} <br />
                  </span>
                </div>
                <div className="cmc" style={{ fontSize: "14px" }}>
                  <span style={{ color: "" }}> Date: {getISTDate()}</span>
                  <span style={{ color: "" }}> Time: {getISTTime()}</span>
                </div>
              </div>
              <div className="live_stream">
                <div className="main-websocket">
                  <Cassinoifram id={3056} />
                </div>
                <div className="overflow-card-section">
                  <div className="stream-card-section">
                    <div className="cmd">
                      <img className="card-image" src={C1} />
                    </div>
                  </div>
                </div>
                <div className="overflow-casino-timer">
                  <FlipCountdown endAt={timmer} />
                </div>
              </div>

              <div
                style={{ margin: "0px" }}
                className="mt-2 row select_bet_box"
              >
                <div className=" col-md-4 ">
                  <div onClick={betConform} className="tc d-grid">
                    <p>
                      <span style={{ color: "red", fontWeight: "bold" }}>
                        A.
                      </span>
                      Amar{" "}
                    </p>
                    <div
                      onClick={() => {
                        t2Amar?.gstatus !== "SUSPENDED" &&
                          handelPlacebet(t2Amar);
                      }}
                      style={{ position: "relative", width: "100%" }}
                    >
                      <div>
                        <button
                          style={{ width: "100%" }}
                          className="bet_box_true"
                        >
                          {t2Amar?.b1 || 0}
                        </button>
                      </div>
                      {t2Black?.gstatus === "SUSPENDED" && <OverflowContant />}
                    </div>
                    <span className='min-max'> min:{t2Amar?.min} || max:{t2Amar?.max}</span>
                  </div>
                </div>
                <div className=" col-md-4 ">
                  <div
                    onClick={() => {
                      t2Akbar?.gstatus !== "SUSPENDED" &&
                        handelPlacebet(t2Akbar);
                    }}
                    className="tc d-grid"
                  >
                    <p>
                      <span style={{ color: "red", fontWeight: "bold" }}>
                        B.
                      </span>
                      Akbar
                    </p>
                    <div style={{ position: "relative", width: "100%" }}>
                      <div>
                        <button
                          style={{ width: "100%" }}
                          className="bet_box_true"
                        >
                          {t2Akbar?.b1 || 0}
                        </button>
                      </div>
                      {t2Black?.gstatus === "SUSPENDED" && <OverflowContant />}
                    </div>
                    <span className='min-max'> min:{t2Akbar?.min} || max:{t2Akbar?.max}</span>
                  </div>
                </div>
                <div className=" col-md-4">
                  <div
                    onClick={() => {
                      t2Anthony?.gstatus !== "SUSPENDED" &&
                        handelPlacebet(t2Anthony);
                    }}
                    className="tc d-grid"
                  >
                    <p>
                      <span style={{ color: "red", fontWeight: "bold" }}>
                        C.
                      </span>
                      Anthony
                    </p>
                    <div style={{ position: "relative", width: "100%" }}>
                      <div>
                        <button
                          style={{ width: "100%" }}
                          className="bet_box_true"
                        >
                          {t2Anthony?.b1 || 0}
                        </button>
                      </div>
                      {t2Black?.gstatus === "SUSPENDED" && <OverflowContant />}
                    </div>
                    <span className='min-max'> min:{t2Anthony?.min} || max:{t2Anthony?.max}</span>
                  </div>
                </div>
              </div>




              <div style={{ margin: "0px" }} className="row cmc">

                <div className="select_bet_box mt-2 col-md-4">
                  <div
                    style={{ width: "" }}
                    className=" "
                  >
                    <div style={{ width: "100%" }} className="">
                      <div
                        onClick={() => {
                          t2Even?.gstatus !== "SUSPENDED" &&
                            handelPlacebet(t2Even);
                        }}
                        className="tc d-grid"
                        style={{ position: "relative" }}
                      >
                        <button className="bet_box_even t-white">EVEN</button>
                        {t2Even?.gstatus === "SUSPENDED" && (
                          <OverflowContantfancy />
                        )}

                        <span>{t2Even?.b1 || 0}</span>
                      </div>
                      <div
                        onClick={() => {
                          t2Odd?.gstatus !== "SUSPENDED" && handelPlacebet(t2Odd);
                        }}
                        className="tc d-grid"
                        style={{ position: "relative" }}
                      >
                        <button className="bet_box_even">ODD</button>
                        {t2Odd?.gstatus === "SUSPENDED" && (
                          <OverflowContantfancy />
                        )}
                        <span>{t2Odd?.b1 || 0}</span>
                      </div>
                    </div>
                  </div>
                  <div className="w-100">     <span className='min-max'> min:{t2Even?.min} || max:{t2Even?.max}</span> </div>
                </div>

                <div className="select_bet_box mt-2 col-md-4 ">
                  <div
                    style={{ width: "" }}
                    className=""
                  >
                    <div>
                      <div
                        onClick={() => {
                          t2Red?.gstatus !== "SUSPENDED" && handelPlacebet(t2Red);
                        }}
                        className="tc d-grid"
                        style={{ position: "relative" }}
                      >
                        <button className="bet_box_true bet_box_even">
                          <div className="luckeybet-sign-button">
                            <img src={heart} />
                            <img src={diamond} />
                          </div>
                        </button>
                        {t2Red?.gstatus === "SUSPENDED" && (
                          <OverflowContantfancy />
                        )}
                        <span>{t2Red?.b1 || 0}</span>
                      </div>
                      <div
                        onClick={() => {
                          t2Black?.gstatus !== "SUSPENDED" &&
                            handelPlacebet(t2Black);
                        }}
                        className="tc d-grid"
                        style={{ position: "relative" }}
                      >
                        <button className="bet_box_false bet_box_even">
                          <div className="luckeybet-sign-button">
                            <img src={shade} />
                            <img src={club} />
                          </div>
                        </button>
                        {t2Black?.gstatus === "SUSPENDED" && (
                          <OverflowContantfancy />
                        )}
                        <span>{t2Black?.b1 || 0}</span>
                      </div>
                    </div>
                  </div>
                  <span className='min-max'> min:{t2Red?.min} || max:{t2Red?.max}</span>
                </div>

                <div className="select_bet_box mt-2 col-md-4 ">
                  <div
                    style={{ width: "" }}
                    className=""
                  >
                    <div className="">
                      <div
                        onClick={() => {
                          t2Under7?.gstatus !== "SUSPENDED" &&
                            handelPlacebet(t2Under7);
                        }}
                        className="tc d-grid"
                        style={{ position: "relative" }}
                      >
                        <button className="bet_box_even">UNDER 7</button>
                        {t2Under7?.gstatus === "SUSPENDED" && (
                          <OverflowContantfancy />
                        )}
                        <span>{t2Under7?.b1 || 0}</span>
                      </div>
                      <div
                        onClick={() => {
                          t2Over7?.gstatus !== "SUSPENDED" &&
                            handelPlacebet(t2Over7);
                        }}
                        className="tc d-grid"
                        style={{ position: "relative" }}
                      >
                        <button className="bet_box_even">UNDER 7</button>
                        {t2Over7?.gstatus === "SUSPENDED" && (
                          <OverflowContantfancy />
                        )}
                        <span>{t2Over7?.b1 || 0}</span>
                      </div>
                    </div>
                  </div>
                  <span className='min-max'> min:{t2Under7?.min} || max:{t2Under7?.max}</span>
                </div>
              </div>


              <div className="select_bet_box">

                <div className="mt-2  d-flex justify-content-center cardssection">
                  <div className="card-grid">
                    {cardsimgnew.map((card, index) => (
                      <div
                        onClick={() => {
                          card.data?.gstatus !== "SUSPENDED" && handelPlacebet(card?.data);
                        }}
                        key={index}
                        className="card-container"
                      >
                        <div
                          style={{ backgroundColor: "red", position: "relative" }}
                          className=""
                        >
                          <img
                            className="card-image"
                            src={card.name}
                            alt={`Card ${index}`}
                          // onClick={() => handleCardClick(card.value)}
                          />
                          {card.data?.gstatus === "SUSPENDED" && <OverflowCard />}
                        </div>
                        <div className="card-text">{card?.data?.b1 || 0}</div>

                      </div>
                    ))}

                  </div>


                </div>
                <div> <span className="min-max">min:{t2Card7?.min} || max: {t2Card7?.max}</span></div>
              </div>

              <div className="last_result mt-3 cmc">
                <span>Last Result</span>
                <span>View Result</span>
              </div>
              <div className="bet-result" style={{ padding: "10px 10px" }}>
                {aaabetResult.map((item, index) => (
                  <span
                    key={index}
                    style={{
                      color:
                        item.result === "1"
                          ? "red"
                          : item.result === "2"
                            ? "yellow"
                            : item.result === "3"
                              ? "black"
                              : "black", // Fallback for any other values
                    }}
                  >
                    {item.result}
                  </span>
                ))}
              </div>
            </div>
          </div>

          <div className="col-md-4">
            {notCompletedBetList && notCompletedBetList.length != 0 &&

              <div>
                <div className="place-bet-h">Open Bet List</div>
                <CompletedBetList columns={columnsopenbet} listData={notCompletedBetList} />
              </div>
            }
            {checkProfitLoss(betlist)?.totalAmount !== 0 &&
              <div>
                <div className="place-bet-h cmc">
                  <div>Completed Bet List</div>
                  <div
                    style={{
                      color: checkProfitLoss(betlist)?.isProfit
                        ? "yellow"
                        : "red",
                    }}
                  >
                    P/L: {checkProfitLoss(betlist).profitLossAmount}
                  </div>
                </div>
                <CompletedBetList columns={columns} listData={completedBetList} />
              </div>
            }
          </div>
        </div>
      </div>
      <div style={{ margin: "10px" }}>
        {" "}
        <BackButton onClick={handleClick} />{" "}
      </div>
    </>
  );
};
export default AmarAkbar;

const OverflowContant = () => {
  return (
    <>
      <div
        className="overflow-aaa-btn1"
        style={{
          width: "100%",
        }}
      >
        <LockIcon sx={{ color: "white" }} />
      </div>
    </>
  );
};

const OverflowContantfancy = () => {
  return (
    <>
      <div style={{ top: 13 }} className="overflow-dragon-tiger-btn1">
        <LockIcon sx={{ color: "white" }} />
      </div>
    </>
  );
};

const OverflowCard = () => {
  return (
    <>
      <div className="overflow-dragon-tiger-card">
        <LockIcon sx={{ color: "white" }} />
      </div>
    </>
  );
};
