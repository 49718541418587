import { useDispatch } from "react-redux";
import { GetFanctBet, GetFancyBet, GetGamingMatches, GetScorecardlink, LoginApi } from "../../service/apiconst";
import { useGetAPI, usePostAPI } from "../../service/apiServices";
import { GamingPlacebet } from "../../service/apiconst";

function PlaceSassionBet() {
  const dispatch = useDispatch();

  return usePostAPI({
    contenttype: false,
    url: GamingPlacebet(),
    dispatch: (e) => {
      dispatch({ type: e.type, payload: e.payload });
    },
  });
}
export default PlaceSassionBet;


export const  GetFancyresult =(id) => {

  const dispatch = useDispatch();

  return useGetAPI({

    contenttype: false,
    url: GetFancyBet(id),
    autoRunStop:false,
    dispatch: (e) => {
      // dispatch(e);
    },
  });
}

export const  GetMatchsList =() => {
  const dispatch = useDispatch();

  return useGetAPI({
    contenttype: false,
    url: GetGamingMatches(),
    autoRunStop:false,
    dispatch: (e) => {
      dispatch(e);
    },
  });
}

export const  GetscorecardLink =(item) => {
  const dispatch = useDispatch();

  return useGetAPI({
    contenttype: false,
    url: GetScorecardlink(item),
    autoRunStop:false,
    dispatch: (e) => {
      dispatch(e);
    },
  });
}


// const { response, loading,refetch } = useGetAPI({ url: GetGamingMatches() });


