import React, { useEffect } from 'react';
// import inplay2 from '../../assets/images/implay2.png';
// import casino2 from '../../assets/images/casino2.jpg';
// import ledger2 from '../../assets/images/ledger2.png';
// import profile2 from '../../assets/images/profile2.png';
// import password2 from '../../assets/images/password2.png';
// import hsattlement from '../../assets/images/hsattlement.png';
import inplay2 from '../../assets/images/inplayNN.png';
import casino2 from '../../assets/images/casinoN.png';
import ledger2 from '../../assets/images/ledgerN.png';
import profile2 from '../../assets/images/profileN.png';
import password2 from '../../assets/images/passwordN.png';
import hsattlement from '../../assets/images/statementN.png';
import complete2 from '../../assets/images/compleat2.jpg';
import 'bootstrap/dist/css/bootstrap.min.css';
import { accountstatement, casino, changeclientpassword, inplay, myledger, profile } from '../../routesconfig/constant';
import { Link } from 'react-router-dom';
import CountdownTimer, { Countdown, Countdown2, Countdown3, Countdown4, Countdown5, Countdown6, Countdown7, Countdown8 } from '../common/countdown';
import { FetchUserDetails } from '../../api/cassino/cassinogame';
import { setUserDetail } from '../../store/feature/authSlice';
import { useDispatch } from 'react-redux';






const Home = () => {
    const dispatch = useDispatch()



    const { response: responseprofile, refetch: Refetchprofile } = FetchUserDetails();
    const userdetails = responseprofile?.data;

    useEffect(() => {
        if (responseprofile?.data) {


            dispatch(setUserDetail(userdetails));
        }
    }, [responseprofile])


    const data = [
        {
            img: inplay2,
            label: 'IN PLAY',
            link: inplay
        },
        {
            img: casino2,
            label: 'CASINO',
            link: casino
        },
        {
            img: hsattlement,
            label: 'STATEMENT',
            link: accountstatement
        },
        // {
        //     img: complete2,
        //     label: 'COMPLETE GAME',
        //     link: ""
        // },
        {
            img: profile2,
            label: 'MY PROFILE',
            link: profile
        },
        {
            img: ledger2,
            label: 'MY LEDGER',
            link: myledger
        },
        {
            img: password2,
            label: 'CHANGE PASSWORD',
            link: changeclientpassword
        }
    ];

    return (
        <div className="home-dashboard container">


            <div className="row">
                {data.map((item, index) => (
                    <div className="col-md-6 col-sm-12 mt-3" key={index}>
                        <Link to={item?.link ? item?.link : '/'}> <div className='home-option-card'>
                            <img src={item.img} className="card-img-top" alt={item.label} />
                        </div>
                        </Link>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Home;
