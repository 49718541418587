import { useDispatch } from "react-redux";
import { useGetAPI, usePostAPI } from "../../service/apiServices";
import { CasinoPlacebet, GetCasinobetList, GetCasinobetListbydate, GetCasinodata, GetCasinogameResult, GetCasinogameResultList, Userdetails } from "../../service/apiconst";


export const GetcasinoData = (item) => {
    const dispatch = useDispatch();
    return useGetAPI({
        contenttype: false,
        url: GetCasinodata(item),
        autoRunStop: false,
        dispatch: (e) => {
            // dispatch(e);
        },
        bearerToken: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9zaWQiOiI3YmU4YWNkNS03MTUxLTQwNzktYTRiOS1iZDZkMjlmZDIyMGUiLCJodHRwOi8vc2NoZW1hcy5taWNyb3NvZnQuY29tL3dzLzIwMDgvMDYvaWRlbnRpdHkvY2xhaW1zL3JvbGUiOiJUZXN0IiwiZXhwIjoxODg1MzIxMDUyLCJpc3MiOiJodHRwOi8vbG9jYWxob3N0OjcyNDMiLCJhdWQiOiJodHRwOi8vbG9jYWxob3N0OjQyMDAifQ.I64YO0IjYEXbJhjdhu5i_ti0u2Ak9hRU6YTa6O8gQFc'
    });
}



export const FetchUserDetails = () => {
    const dispatch = useDispatch();
    return useGetAPI({
        contenttype: false,
        url: Userdetails(),
        autoRunStop: false,
        dispatch: (e) => {
            // dispatch(e);
        },

    });
}


export const GetcasinoResult = (item) => {
    const dispatch = useDispatch();
    return useGetAPI({
        contenttype: false,
        url: GetCasinogameResult(item),
        autoRunStop: false,
        dispatch: (e) => {
            // dispatch(e);
        },
        bearerToken: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9zaWQiOiI3YmU4YWNkNS03MTUxLTQwNzktYTRiOS1iZDZkMjlmZDIyMGUiLCJodHRwOi8vc2NoZW1hcy5taWNyb3NvZnQuY29tL3dzLzIwMDgvMDYvaWRlbnRpdHkvY2xhaW1zL3JvbGUiOiJUZXN0IiwiZXhwIjoxODg1MzIxMDUyLCJpc3MiOiJodHRwOi8vbG9jYWxob3N0OjcyNDMiLCJhdWQiOiJodHRwOi8vbG9jYWxob3N0OjQyMDAifQ.I64YO0IjYEXbJhjdhu5i_ti0u2Ak9hRU6YTa6O8gQFc'
    });
}

export const GetcasinoBetList = (item) => {
    const dispatch = useDispatch();
    return useGetAPI({
        contenttype: false,
        url: GetCasinobetList(item),
        autoRunStop: false,
        dispatch: (e) => {
            // dispatch(e);
        },

    });
}
export const CasinobetListbydate = (params) => {
    const dispatch = useDispatch();
    return useGetAPI({
        contenttype: false,
        url: GetCasinobetListbydate(),
        autoRunStop: false,
        params,
        dispatch: (e) => {
            // dispatch(e);
        },

    });
}

function SubmitCasinoBet() {
    const dispatch = useDispatch();

    return usePostAPI({
        contenttype: false,
        url: CasinoPlacebet(),
        dispatch: (e) => {
            dispatch({ type: e.type, payload: e.payload });

         
            // if (e.payload.type !== "success") {
            // }
        },
        // bearerToken: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9zaWQiOiI3YmU4YWNkNS03MTUxLTQwNzktYTRiOS1iZDZkMjlmZDIyMGUiLCJodHRwOi8vc2NoZW1hcy5taWNyb3NvZnQuY29tL3dzLzIwMDgvMDYvaWRlbnRpdHkvY2xhaW1zL3JvbGUiOiJUZXN0IiwiZXhwIjoxODgzMzEwNTkwLCJpc3MiOiJodHRwOi8vbG9jYWxob3N0OjcyNDMiLCJhdWQiOiJodHRwOi8vbG9jYWxob3N0OjQyMDAifQ.y1HoK-0L6kfS5bnRZrDLzmhcbQTgQj2ZtGN4Uhi7JYs'
    });
}

export default SubmitCasinoBet;