import { format } from "date-fns";

export const formatDate = (dateString) => {
    const date = new Date(dateString);
    return format(date, "dd-MM-yyyy hh:mm a");
};



export const formatDateTime = (dateString) => {
    try {
      // Remove any extra fractional seconds
      const sanitizedDateString = dateString.replace(/\.\d+/, "");
      const date = new Date(sanitizedDateString);
  
      if (isNaN(date)) {
        throw new Error("Invalid date format");
      }
  
      return format(date, "dd-MM-yyyy hh:mm a");
    } catch (error) {
      console.error("Error formatting date:", error.message);
      return "Invalid date";
    }
  };
  