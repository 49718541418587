import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setApierror,
  setLoader,
  setSnackbaralert,
} from "../store/feature/authSlice";
import { useNavigate } from "react-router-dom";

import { errorresult, login } from "../routesconfig/constant";
import { useSocket } from "./socket";

const GetCaller = ({ API, callback, item, dispatch, enquirybranch }) => {
  const authToken = localStorage.getItem("authToken");

  dispatch({ type: "LOADER", payload: true });

  const requestData = item
    ? item
    : {
      searchKey: "",
      sortKey: "",
      sortDirection: "",
      itemsPerPage: 5,
      page: 1,
    };
  const headers = {
    Authorization: `Bearer ${authToken}`, // Include the Bearer token in the Authorization header
  };
  axios
    .get(API(requestData), { headers })
    .then((response) => {
      dispatch({ type: "LOADER", payload: false });
      const friendlymess = response?.data?.metaData?.friendlyMessage;
      const resultmess = response?.data?.metaData?.resultMessage;
      if (response?.data?.metaData?.resultCode === "R00") {
        callback({ response });

        dispatch({
          type: "SNACKBARALERT",
          payload: {
            active: true,
            message: friendlymess || resultmess,
            type: "success",
          },
        });
      } else {
        dispatch({
          type: "SNACKBARALERT",
          payload: {
            active: true,
            message: friendlymess || resultmess,
            type: "error",
          },
        });
      }
    })
    .catch((error) => {
      dispatch({ type: "LOADER", payload: false });
      // callback({ error });

      if (error.response) {
        // The request was made and the server responded with a status code
        if (error.response.status === 401) {
          localStorage.clear();
          sessionStorage.clear();
          window.location.href = login;
          window.location.reload();
          // Handle 401 Unauthorized (invalid token or session expired)
          // You can redirect to a login page or take appropriate action

          console.error(
            "Unauthorized: Redirect to login page or refresh token."
          );
        } else {
          // Handle other error responses
          callback({ error: error?.response?.data });
        }
      } else if (error.request) {
        // The request was made but no response was received
        console.error("No response received from the server.");
      } else {
        // Something happened in setting up the request that triggered an Error
        console.error("Error setting up the request:", error.message);
      }
    });
};
export default GetCaller;

export const PostCaller = ({
  API,
  callback,
  item,
  dispatch,
  contenttype,
  type,
}) => {
  const authToken = sessionStorage.getItem("accessToken");

  dispatch({ type: "LOADER", payload: true });
  const headers = {
    "Content-Type": contenttype ? "multipart/form-data" : "application/json",
    Authorization: `Bearer ${authToken}`, // Include the Bearer token in the Authorization header
    // "FinancialYear": financialYear,
    // "Branch": `${enquirybranch}`
  };

  axios
    .post(API(item), item && item, { headers })
    .then((response) => {
      dispatch({ type: "LOADER", payload: false });
      const friendlymess = response?.data?.metaData?.friendlyMessage;
      const resultmess = response?.data?.metaData?.resultMessage;

      if (response?.data?.metaData?.resultCode === "R00") {
        callback({ response });

        dispatch(
          setSnackbaralert({
            active: true,
            message: friendlymess || resultmess,
            type: "success",
          })
        );
      } else {
        dispatch(
          setSnackbaralert({
            active: true,
            message: friendlymess || resultmess,
            type: "error",
          })
        );
      }
    })
    .catch((error) => {
      if (error.response) {
        // The request was made and the server responded with a status code
        if (error.response.status === 401) {
          localStorage.clear();
          sessionStorage.clear();
          window.location.href = login;
          window.location.reload();


          // Handle 401 Unauthorized (invalid token or session expired)
          // You can redirect to a login page or take appropriate action
          console.error(
            "Unauthorized: Redirect to login page or refresh token."
          );
        } else {
          // Handle other error responses
          callback({ error: error?.response?.data });
        }
      } else if (error.request) {
        // The request was made but no response was received
        console.error("No response received from the server.");
      } else {
        // Something happened in setting up the request that triggered an Error
        console.error("Error setting up the request:", error.message);
      }
    });
};


export const PutCaller = ({
  API,
  callback,
  item,
  dispatch,
  contenttype,
  type,
}) => {
  const authToken = sessionStorage.getItem("accessToken");

  dispatch({ type: "LOADER", payload: true });
  const headers = {
    "Content-Type": contenttype ? "multipart/form-data" : "application/json",
    Authorization: `Bearer ${authToken}`, // Include the Bearer token in the Authorization header
    // "FinancialYear": financialYear,
    // "Branch": `${enquirybranch}`
  };

  axios
    .put(API(item), item && item, { headers })
    .then((response) => {
      dispatch({ type: "LOADER", payload: false });
      const friendlymess = response?.data?.metaData?.friendlyMessage;
      const resultmess = response?.data?.metaData?.resultMessage;
      if (response?.data?.metaData?.resultCode === "R00") {
        callback({ response });

        dispatch({
          type: "SNACKBARALERT",
          payload: {
            active: true,
            message: friendlymess || resultmess,
            type: "success",
          },
        });
      } else {
        dispatch({
          type: "SNACKBARALERT",
          payload: {
            active: true,
            message: friendlymess || resultmess,
            type: "error",
          },
        });
      }
    })
    .catch((error) => {
      dispatch({ type: "LOADER", payload: false });
      // callback({ error });
    });
};

export function usePostAPI({ contenttype, url, dispatch, bearerToken }) {

  const authToken = localStorage.getItem("authToken");
  const navigate = useNavigate();

  const [response, setResponse] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const headers = {
    "Content-Type": contenttype ? "multipart/form-data" : "application/json",
    Authorization: `Bearer ${bearerToken ? bearerToken : authToken}`,
  };

  const submit = async (data, onSuccess, params) => {
    setLoading(true);
    setError(null); // Reset error state
    dispatch(setLoader(true));
    debugger
    try {
      debugger
      const result = await axios.post(url, data, {
        headers,
        ...(typeof params === "object" ? { params: params } : {}),
      });

      const { friendlyMessage, resultMessage, resultCode } =
        result?.data?.metaData || {};

      if (resultCode === "R00") {
        setResponse(result.data);

        dispatch(
          setSnackbaralert({
            active: true,
            message: friendlyMessage || resultMessage,
            type: "success",
          })
        );

        onSuccess(result.data);
      } else {
        dispatch(
          setSnackbaralert({
            active: true,
            message: friendlyMessage || resultMessage || "error",
            type: "error",
          })
        );
      }
    }

    catch (error) {
      debugger

      if (error.response) {
        debugger
        if (error.response.status === 401) {

          localStorage.clear();
          navigate(login);
          // window.location.reload();
          console.error(
            "Unauthorized: Redirect to login page or refresh token."
          );
        } else {
          // Handle other error responses
        }
      } else if (error.request) {
        dispatch(setApierror(error.message));
        navigate(errorresult);
        // The request was made but no response was received
        console.error("No response received from the server.");
      } else {
        dispatch(setApierror(error.message));
        navigate(errorresult);
        console.error("Error setting up the request:", error.message);
        navigate(errorresult);
      }
      setError(error);
    } finally {
      setLoading(false);
      dispatch(setLoader(false));
    }
  };

  return { response, loading, error, submit };
}

export function usePutAPI({ contenttype, url, dispatch, bearerToken }) {
  const authToken = localStorage.getItem("authToken");
  const navigate = useNavigate();

  const [response, setResponse] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const headers = {
    "Content-Type": contenttype ? "multipart/form-data" : "application/json",
    Authorization: `Bearer ${bearerToken ? bearerToken : authToken}`,
  };




  const submit = async (data, onSuccess, params) => {
    setLoading(true);
    setError(null); // Reset error state
    dispatch(setLoader(true));
    try {
      const result = await axios.put(url, data, {
        headers,
        ...(typeof params === "object" ? { params: params } : {}),
      });

      const { friendlyMessage, resultMessage, resultCode } =
        result?.data?.metaData || {};

      if (resultCode === "R00") {
        setResponse(result.data);

        dispatch(
          setSnackbaralert({
            active: true,
            message: resultMessage || friendlyMessage,
            type: "success",
          })
        );

        onSuccess(result.data);
      } else {
        dispatch(
          setSnackbaralert({
            active: true,
            message: resultMessage || friendlyMessage || "error",
            type: "error",
          })
        );
      }
    } catch (error) {

      if (error.response) {
        if (error.response.status === 401) {
          localStorage.clear();
          navigate(login);
          // window.location.reload();
          console.error(
            "Unauthorized: Redirect to login page or refresh token."
          );
        } else {
          // Handle other error responses
        }
      } else if (error.request) {
        dispatch(setApierror(error.message));
        navigate(errorresult);
        // The request was made but no response was received
        console.error("No response received from the server.");
      } else {
        dispatch(setApierror(error.message));
        navigate(errorresult);
        console.error("Error setting up the request:", error.message);
        navigate(errorresult);
      }
      setError(error);
    } finally {
      setLoading(false);
      dispatch(setLoader(false));
    }
  };

  return { response, loading, error, submit };
}


export function useGetAPI({ url, params, autoRunStop, dispatch, bearerToken }) {
  const [response, setResponse] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const authToken = localStorage.getItem("authToken");

  const navigate = useNavigate()

  const headers = {
    Authorization: `Bearer ${bearerToken ? bearerToken : authToken}`,
  };


  const fetchData = async (fatchParems) => {
    setLoading(true);
    setError(null); // Reset error state
    dispatch(setLoader(true));
    try {
      const result = await axios.get(url, {
        headers,
        params: fatchParems || params,
      });
      setResponse(result.data);
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          localStorage.clear();
          window.location.reload()
        } else {
          // Handle other error responses
        }
      } else if (error.request) {
        console.error("No response received from the server.");
      } else {
        console.error("Error setting up the request:", error.message);
      }
      setError(error);
    } finally {
      dispatch(setLoader(false));
      setLoading(false);
    }
  };

  useEffect(() => {
    if (url && !autoRunStop) {
      fetchData();
    }
  }, [url]); // Fetch data when url or params change

  return { response, loading, error, refetch: fetchData };
}

// dispatch(setSnackbaralert({ active: true, message: friendlymess || resultmess, type: 'success' }));
// dispatch(setSnackbaralert({ active: true, message: friendlymess || resultmess, type: 'error' }));




// src/hooks/usePostAPI.js



export function useSocketPost({ contenttype, url, dispatch }) {
  const authToken = localStorage.getItem("authToken");
  const navigate = useNavigate();
  const { socket } = useSocket(); // Get the socket instance

  const [response, setResponse] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const headers = {
    "Content-Type": contenttype ? "multipart/form-data" : "application/json",
    Authorization: `Bearer ${authToken}`,
  };

  const submit = async (data, onSuccess, params) => {
    setLoading(true);
    setError(null); // Reset error state
    dispatch(setLoader(true));

    try {
      const result = await axios.post(url, data, {
        headers,
        ...(typeof params === "object" ? { params: params } : {}),
      });

      const { friendlyMessage, resultMessage, resultCode } = result?.data?.metaData || {};

      if (resultCode === "R00") {
        setResponse(result.data);

        dispatch(setSnackbaralert({
          active: true,
          message: friendlyMessage || resultMessage,
          type: "success",
        }));

        onSuccess(result.data);

        // Emit an event via socket after a successful API post
        if (socket) {
          socket.emit('newDataPosted', result.data); // Emit event to server
        }
      } else {
        dispatch(setSnackbaralert({
          active: true,
          message: friendlyMessage || resultMessage,
          type: "error",
        }));
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          localStorage.clear();
          sessionStorage.clear();
          window.location.reload();
          navigate("/home");
          console.error("Unauthorized: Redirect to login page or refresh token.");
        } else {
          console.error("Error response:", error.response);
        }
      } else if (error.request) {
        console.error("No response received from the server.");
      } else {
        console.error("Error setting up the request:", error.message);
      }
      setError(error);
    } finally {
      setLoading(false);
      dispatch(setLoader(false));
    }
  };

  return { response, loading, error, submit };
}







export function useSocketGetAPI({ url, dispatch, autoRunStop = true, params }) {
  const authToken = localStorage.getItem("authToken");
  const navigate = useNavigate();
  const { socket } = useSocket(); // Get the socket instance

  const [response, setResponse] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const headers = {
    Authorization: `Bearer ${authToken}`,
  };

  const fetchData = async () => {
    setLoading(true);
    setError(null); // Reset error state
    dispatch(setLoader(true));

    try {
      const result = await axios.get(url, {
        headers,
        ...(typeof params === "object" ? { params: params } : {}),
      });

      const { friendlyMessage, resultMessage, resultCode } = result?.data?.metaData || {};

      if (resultCode === "R00") {
        setResponse(result.data);

        dispatch(setSnackbaralert({
          active: true,
          message: friendlyMessage || resultMessage,
          type: "success",
        }));

        // Emit an event via socket after a successful GET request
        if (socket) {
          socket.emit('newDataFetched', result.data); // Emit event to server
        }
      } else {
        dispatch(setSnackbaralert({
          active: true,
          message: friendlyMessage || resultMessage,
          type: "error",
        }));
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          localStorage.clear();
          sessionStorage.clear();
          window.location.reload();
          navigate("/home");
          console.error("Unauthorized: Redirect to login page or refresh token.");
        } else {
          console.error("Error response:", error.response);
        }
      } else if (error.request) {
        console.error("No response received from the server.");
      } else {
        console.error("Error setting up the request:", error.message);
      }
      setError(error);
    } finally {
      setLoading(false);
      dispatch(setLoader(false));
    }
  };

  // Auto run the fetchData when component mounts or `autoRunStop` is true
  useEffect(() => {
    if (autoRunStop) {
      fetchData();
    }
  }, [url, params, autoRunStop]);

  return { response, loading, error, fetchData };
}
