
export const BaseURL = {
  // URL: process.env.REACT_APP_api_base_url
  //  URL:'http://qa.bbet247.net/'
   URL: 'https://bbet247.net/'
  // URL: 'https://localhost:7284/'


}



export const LoginApi = () => `${BaseURL.URL}Auth/sign-in`;
export const Userdetails = () => `${BaseURL.URL}Client/get-details`;
export const UserTheme = (themename) => `${BaseURL.URL}client/theme/${themename}`;
export const UserupdatePassword = () => `${BaseURL.URL}client/update-password`;
export const GetGamingMatches = () => `${BaseURL.URL}api/cricket/inplayevents`;
export const GamingPlacebet = () => `${BaseURL.URL}api/cricket/placebet`;
export const GetFancyBet = (item) => `${BaseURL.URL}api/cricket/fancybets/${item}`;
export const GetCricketSassion = (id) => `${BaseURL.URL}api/cricket/sessions/${id}`
export const GetCricketOdds = (id) => `${BaseURL.URL}api/cricket/marketodds/${id}`
export const GetCasinodata = (item) => `${BaseURL.URL}api/bagpackkar/getdata-${item}`
export const GetCasinogameResult = (item) => `${BaseURL.URL}api/casino/getresult/${item}`
export const CasinoPlacebet = () => `${BaseURL.URL}api/csn/placebet`;
export const GetCasinobetList = (item) => `${BaseURL.URL}api/csn/betlist/${item}`
export const GetCasinogameResultList = (item) => `${BaseURL.URL}api/casino/getdetailresult${item}`
export const GetScorecardlink = (item) => `${BaseURL.URL}api/cricket/scorecard/${item}`
export const GetAccountstatement = () => `${BaseURL.URL}client/stetement`;
export const getLedgerdata = (item) => `${BaseURL.URL}client/ledger`;
export const GetCasinobetListbydate = (item) => `${BaseURL.URL}api/csn/betlist`


